import "./index.css";
import { useEffect, useState } from "react";
import { Suspense } from "./suspense";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useMoodleV2 } from "../../../contexts/moodle";

export const Contacts = ({ selected, contacts, setContacts, createChat }) => {
  const [searching, setSearching] = useState(false);
  const [searchedContacts, setSearchedContacts] = useState(null);
  const [contactRequests, setContactRequests] = useState(null);
  const [showRequests, setShowRequests] = useState(true);
  let isSelectedView = selected === "contacts";
  let moodle = useMoodleV2();

  useEffect(() => {
    if (isSelectedView) {
      moodle
        .getContactRequests()
        .then((res) => {
          setContactRequests(res);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [isSelectedView]);

  useEffect(() => {
    if (isSelectedView && !contacts) {
      setTimeout(() => {
        moodle
          .getContacts()
          .then((data) => {
            setContacts(data);
          })
          .catch((err) => console.log(err));
      }, 2000);
    }

    return () => {
      setSearchedContacts(null);
    };
    // eslint-disable-next-line
  }, [isSelectedView]);

  const handleSearch = ({ target: { value } }) => {
    if (value.length < 1) {
      setSearching(false);
      setSearchedContacts([]);
    }
    if (value.length < 3) {
      return;
    }
    setSearching(true);
    moodle
      .searchContacts(value)
      .then((res) => {
        setSearching(false);
        setSearchedContacts(res);
      })
      .catch((err) => {
        setSearching(false);
        console.log(err);
      });
  };

  if (isSelectedView && contacts) {
    return (
      <div>
        {contactRequests && contactRequests.length > 0 && (
          <div
            onClick={() => setShowRequests(!showRequests)}
            className="px-3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "1px solid #ccc",
              paddingBottom: 20,
              paddingTop: 22,
              cursor: "pointer",
            }}
          >
            <p style={{ color: "grey" }}>
              {showRequests ? "Ocultar" : "Ver"} {contactRequests.length}{" "}
              solicitudes de contacto
            </p>
          </div>
        )}
        {showRequests &&
          contactRequests &&
          contactRequests.length > 0 &&
          contactRequests.map((user, idx) => {
            return (
              <ContactItem
                profile={user}
                withRequest={true}
                setContactRequests={setContactRequests}
                setContacts={setContacts}
              />
            );
          })}
        <div className="px-3 pt-4 mb-3">
          <input
            className="form-control"
            type={"text"}
            placeholder="Buscar contactos"
            onChange={handleSearch}
          />
        </div>
        {searching && <Suspense />}
        <div
          style={{
            borderTop: "1px solid #ccc",
            marginTop: "1.3rem",
            overflowY: "scroll",
            maxHeight: "80vh",
          }}
        >
          {searchedContacts &&
            searchedContacts.map((user) => (
              <ContactItem
                profile={user}
                key={user.id}
                createChat={createChat}
              />
            ))}
          {contacts.map((user) => (
            <ContactItem profile={user} key={user.id} createChat={createChat} />
          ))}
        </div>
      </div>
    );
  }

  if (isSelectedView && !contacts) {
    return <Suspense />;
  }

  return null;
};

const ContactItem = ({
  profile = null,
  createChat,
  withRequest = false,
  setContactRequests,
  setContacts,
}) => {
  const moodle = useMoodleV2();
  if (!profile) {
    return null;
  }

  let status = profile?.isonline ? "Online" : "Offline";

  let styles = {};

  styles.container = {
    padding: "2rem",
    borderBottom: "1px solid #ccc",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };

  styles.img = {
    borderRadius: "50%",
    marginRight: "2rem",
  };

  styles.status = {
    marginTop: "3px",
    fontSize: "10px",
    textTransform: "uppercase",
    letterSpacing: "1px",
    fontWeight: 500,
    backgroundColor: status === "Offline" ? "#d50000" : "#43a047",
    color: "#fff",
    width: "fit-content",
    padding: "0 .5rem",
    borderRadius: "2px",
    opacity: 0.5,
  };

  let classes = withRequest ? "contact-item-w" : "contact-item";

  const handleConfirmRequest = () => {
    moodle
      .confirmRequest({ userid: profile.id })
      .then((res) => {
        moodle
          .getContactRequests()
          .then((data) => {
            setContactRequests(data);
          })
          .catch(console.log);
        moodle
          .getContacts()
          .then((data) => {
            setContacts(data);
          })
          .catch(console.log);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancelRequest = () => {
    moodle
      .cancelRequest({ userid: profile.id })
      .then((res) => {
        moodle
          .getContactRequests()
          .then((data) => {
            setContactRequests(data);
          })
          .catch(console.log);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        style={styles.container}
        className={classes}
        onClick={() => createChat(profile)}
      >
        <img alt="..." src={profile?.profileimageurlsmall} style={styles.img} />
        <div>
          <p>{profile?.fullname}</p>
          {!withRequest && <p style={styles.status}>{status}</p>}
        </div>
        {withRequest && (
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              paddingRight: 10,
              gap: 10,
            }}
          >
            <div
              onClick={handleCancelRequest}
              style={{
                backgroundColor: "#ef5350",
                borderRadius: "50%",
                padding: 3,
                opacity: 0.6,
              }}
            >
              <ClearIcon
                sx={{ color: "#fff", fontSize: 20, fontWeight: 500 }}
              />
            </div>
            <div
              onClick={handleConfirmRequest}
              style={{
                backgroundColor: "#43a047",
                borderRadius: "50%",
                padding: 3,
                opacity: 0.6,
              }}
            >
              <CheckIcon sx={{ color: "#fff", fontSize: 20 }} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
