import React from "react";

export const ProgressTable = ({ children, headers }) => {
  return (
    <div className="table-responsive">
      <table className="table table-striped-columns table-hover">
        <thead>
          <tr>
            {headers &&
              headers.map((h, idx) => {
                return (
                  <th
                    style={{ textTransform: "uppercase" }}
                    key={idx}
                    scope="col"
                  >
                    {h}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};
