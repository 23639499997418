import React from "react";
import { defaultHandleChange } from "../../utils/handler";
import { DEFAULT_FORGOT_CREDENTIALS } from "../../utils/state";
import { Snackbar } from "../../components/Snackbar";
import {
  DEFAULT_SNACKBAR,
  ERROR_EMPTY_CREDENTIALS_EMAIL,
  ERROR_EMPTY_CREDENTIALS_USER,
  ERROR_FETCH_PASSWORD,
  ERROR_FETCH_PASSWORD_EMAIL,
  ERROR_FETCH_PASSWORD_USER,
  SUCCESS_EMAIL_SENT,
} from "../../utils/snackbar";
import { ForgotPasswordForm } from "../../components/LoginForm/ForgotPasswordForm";
import { fetcher } from "../../utils";
import { useConfig } from "../../contexts/config";
import { useNavigate } from "react-router-dom";
import "./styles.css";

//usuario y correo
const ForgotPassword = () => {
  const [credentials, setCredentials] = React.useState(
    DEFAULT_FORGOT_CREDENTIALS
  );
  const [loading, setLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(DEFAULT_SNACKBAR);
  const { config } = useConfig();
  const navigate = useNavigate();
  const URL = `${config.baseURL}tipddy/admin/iapp/services/restore-password.php/user/forgotpassword`;
  const headers = { "Content-type": "application/json" };

  // React.useEffect(() => {
  //   document.body.classList.add("imagen3");
  // }, []);

  React.useEffect(() => {
    let bg_img = config.config.theme.background_login;
    document.body.classList.add("imagen2");
    if (localStorage.getItem("token")) navigate("/listcourses");

    if (bg_img) {
      document.body.style.backgroundImage = `url(${bg_img})`;
    }

    return () => {
      document.body.style.backgroundImage = ``;
      document.body.classList.remove("imagen2");
    };
    // eslint-disable-next-line
  }, []);

  const handleSnackbar = (snack) => {
    setSnackbar(snack);
    setTimeout(() => {
      setSnackbar(DEFAULT_SNACKBAR);
    }, 2000);
  };

  const cleaner = () => {
    setCredentials(DEFAULT_FORGOT_CREDENTIALS);
    setLoading(false);
  };

  const handleSubmitUser = async (e) => {
    e.preventDefault();
    console.log("submit user");
    if (credentials.username === "") {
      handleSnackbar(ERROR_EMPTY_CREDENTIALS_USER);
      return;
    }
    try {
      setLoading(true);
      await fetcher(URL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ username: credentials.username }),
      });
      handleSnackbar(SUCCESS_EMAIL_SENT);
      cleaner();
    } catch (error) {
      setLoading(false);
      if (error.message === "400") {
        handleSnackbar(ERROR_FETCH_PASSWORD_USER);
      } else {
        handleSnackbar(ERROR_FETCH_PASSWORD);
      }
    }
  };

  const handleSubmitEmail = async (e) => {
    console.log(credentials);
    e.preventDefault();
    if (credentials.email === "") {
      handleSnackbar(ERROR_EMPTY_CREDENTIALS_EMAIL);
      return;
    }
    try {
      setLoading(true);
      await fetcher(URL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ email: credentials.email }),
      });
      handleSnackbar(SUCCESS_EMAIL_SENT);
      cleaner();
    } catch (error) {
      setLoading(false);
      console.log(typeof error.message);
      if (error.message === "400") {
        handleSnackbar(ERROR_FETCH_PASSWORD_EMAIL);
      } else {
        handleSnackbar(ERROR_FETCH_PASSWORD);
      }
    }
  };

  const handleChange = (e) =>
    defaultHandleChange(e, credentials, setCredentials);

  return (
    <>
      <div className="container esp-log">
        <img
          src="https://par.tuclase.cl/loginalt/images/logo2.png"
          alt="logo"
          className="posi-logo posi-log-b"
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-4  trans2 sac-new">
            <div className="fon-logo-new2 sec-desktop">
              <p className="container-logo-login">
                <img
                  src={config.config.theme.logo}
                  alt="..."
                  className="logo-login"
                />
              </p>
              <p className="iniciar-new">Recuperar Contraseña</p>
            </div>
            <div className="fon-logo-new2 sec-movil">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-xs-8 col-md-8">
                    <p className="">
                      <img
                        alt="img"
                        src="https://par.tuclase.cl/loginalt/images/logo-new2.png"
                        className="img-responsive"
                      />
                    </p>
                  </div>
                  <div className="col-xs-4 col-md-4"></div>
                </div>
                <p className="iniciar-new">Recuperar Contraseña</p>
              </div>
            </div>
            <ForgotPasswordForm
              handleChange={handleChange}
              handleSubmitUser={handleSubmitUser}
              handleSubmitEmail={handleSubmitEmail}
              credentials={credentials}
              loading={loading}
            />
          </div>
          <div className="col-md-7 text-center salir">
            <div className="row">
              <div className="col-md-8"></div>
              <div className="col-md-4"></div>
            </div>
            <h1 className="llamado">
              Ingrese su usuario <br />y su correo.
            </h1>
            <p className="llamado-d">
              luego se le enviará un mail a su correo
              <br />
              con los pasos a seguir
            </p>
          </div>
        </div>
      </div>
      <Snackbar
        severity={snackbar.severity}
        visible={snackbar.visible}
        message={snackbar.message}
      />
    </>
  );
};

export default ForgotPassword;
