import axios from "axios";
/* 	Retrieve a list of conversations for a user */

export const get = async ({ token, search, baseURL }) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_send_instant_messages&messages[0][touserid]=41&messages[0][text]=Mensaje%20de%20prueba&messages[0][textformat]=2&messages[0][clientmsgid]=16`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const sendInstantMessage = async ({
  token,
  message,
  to,
  from,
  baseURL,
}) => {
  try {
    console.log(baseURL);
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_send_instant_messages&messages[0][touserid]=${to}&messages[0][text]=${message}&messages[0][clientmsgid]=${from}`
    );
    console.log(data);
    return data;
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};
/* 	Send Contact request */
export const sendContactRequest = async ({
  token,
  userid,
  requesteduserid,
  baseURL,
}) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_create_contact_request&userid=${userid}&requesteduserid=${requesteduserid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

/* 	Send Contact request */
export const blockUser = async ({ token, userid, blockeduserid, baseURL }) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_block_user&userid=${userid}&blockeduserid=${blockeduserid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
/* 	Send Contact request */
export const unblockUser = async ({
  token,
  userid,
  unblockeduserid,
  baseURL,
}) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_unblock_user&userid=${userid}&unblockeduserid=${unblockeduserid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
/* 	Send Message to Conversation */

export const sendMessagetoConversation = async ({
  token,
  convid,
  message,
  baseURL,
}) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_send_messages_to_conversation&conversationid=${convid}&messages[0][text]=${message}&messages[0][textformat]=2`
    );
    return data;
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};
/* 	Retrieve the conversation messages and relevant member information */

export const getMessagesFromConversation = async ({
  token,
  userid,
  convid,
  baseURL,
}) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_get_conversation_messages&currentuserid=${userid}&convid=${convid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getConversationFromUserId = async ({
  token,
  userid,
  otheruserid,
  baseURL,
}) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_get_conversation_between_users&userid=${userid}&otheruserid=${otheruserid}&includecontactrequests=0&includeprivacyinfo=0&newestmessagesfirst=0`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
/* 	Retrieve a list of conversations for a user */

export const searchContacts = async ({ token, search, baseURL }) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_search_contacts&searchtext=${search}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

/* 	Retrieve a list of conversations for a user */

export const getConversations = async ({ token, userid, baseURL }) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_get_conversations&userid=${userid}&type=1`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

/* 	Gets the number of received contact requests */

export const getRequestCount = async ({ token, userid, baseURL }) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_get_received_contact_requests_count&userid=${userid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
/* Retrieve a self-conversation for a user */

export const getSelfConversation = async ({ token, userid, baseURL }) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_get_self_conversation&userid=${userid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
/* Retrieve the contact list */

export const getContacts = async ({ token, userid, baseURL }) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_get_user_contacts&userid=${userid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Returns contact requests for a user
 */

export const getContactsRequests = async ({ token, userid, baseURL }) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_get_contact_requests&userid=${userid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const confirmRequest = async ({
  token,
  userid,
  requesteduserid,
  baseURL,
}) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_confirm_contact_request&userid=${userid}&requesteduserid=${requesteduserid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const cancelRequest = async ({
  token,
  userid,
  requesteduserid,
  baseURL,
}) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_decline_contact_request&userid=${userid}&requesteduserid=${requesteduserid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getUnreadConversationsCount = async ({
  token,
  userid,
  baseURL,
}) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_get_unread_conversations_count&useridto=${userid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
export const getUnreadConversationCounts = async ({
  token,
  userid,
  baseURL,
}) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_get_unread_conversation_counts&userid=${userid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const markConversationAsRead = async ({
  token,
  userid,
  conversationid,
  baseURL,
}) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_message_mark_all_conversation_messages_as_read&userid=${userid}&conversationid=${conversationid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
