import { useState } from "react";

const tabs = ["Mensajes", "Contactos"];

export const Tabs = ({ setView, selected }) => {
  let styles = {};

  styles.ul = {
    display: "flex",
    listStyle: "none",
    justifyContent: "space-between",
    margin: 0,
    padding: 0,
    marginTop: 0,
  };

  styles.chats = {
    borderBottom: selected === 0 ? "1px solid #fff" : "1px solid #ccc",
    padding: "1rem 0",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    opacity: selected === 0 ? 1 : 0.6,
    textTransform: "uppercase",
    fontWeight: 500,
    cursor: "pointer",
    transition: ".2s",
    backgroundColor: selected === 0 ? "#0079ac" : "unset",
    color: selected === 0 ? "#fff" : "unset",
  };

  styles.contacts = {
    borderBottom: selected === 1 ? "1px solid #fff" : "1px solid #ccc",
    padding: "1rem 0",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    opacity: selected === 1 ? 1 : 0.6,
    textTransform: "uppercase",
    fontWeight: 500,
    cursor: "pointer",
    transition: ".2s",
    backgroundColor: selected === 1 ? "#0079ac" : "unset",
    color: selected === 1 ? "#fff" : "unset",
  };

  return (
    <ul style={styles.ul}>
      <TabItem
        title={tabs[0]}
        onClick={() => setView(0)}
        styles={styles.chats}
      />
      <TabItem
        title={tabs[1]}
        onClick={() => setView(1)}
        styles={styles.contacts}
      />
    </ul>
  );
};

const TabItem = ({ onClick, title, styles }) => {
  const [hover, setHover] = useState(false);

  return (
    <li style={{ ...styles }} onClick={onClick}>
      {title}
    </li>
  );
};
