import { createContext, useContext, useState } from "react";
import {
  fetchClientsIds,
  fetchConfig,
  wordpresstoken,
} from "../services/tipddy-v2/clients";

let LOCALHOST = "localhost";

const ConfigContext = createContext(null);

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);

  let actions = {};

  actions.getConfig = async function () {
    let domain = window.location.host;
    let token = await wordpresstoken();
    let clients = await fetchClientsIds({ token });

    let client = actions.getClientId({ clients, domain });
    let config = await fetchConfig({ token, id: client.ID });

    config = {
      ...config,
      baseURL: client.baseMoodleURL,
    };

    document.title = config.titulo_interfaz || "Campus virtual";

    localStorage.setItem("config", "stage");
    setConfig({ ...config });
    // console.log({ config });
    return config;
  };

  actions.verifyConfig = async function () {};

  actions.getClientId = ({ clients, domain }) => {
    if (domain.includes(LOCALHOST)) {
      let host = domain.split(":")[0];
      let localhostClient = clients.find((c) =>
        c.baseInterfazURL?.includes(host)
      );
      return localhostClient;
    }

    let isValidClient = clients.find((c) =>
      c.baseInterfazURL?.includes(domain)
    );

    if (isValidClient) {
      return isValidClient;
    }
  };

  let value = {
    config,
    getConfig: actions.getConfig,
  };

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export const useConfig = () => useContext(ConfigContext);
