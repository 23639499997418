import React, { useState, useEffect } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useNavigate, useParams } from "react-router-dom";
import { useMoodleV2 } from "../../contexts/moodle";
// import { useAuth } from "../../contexts/AuthContext";
import { CourseContentModuleRowComponent } from "../ModuleContentRowComponent";
import groupIcon from "../../assets/grupo-gris.png";
import Skeleton from "react-loading-skeleton";
import "./index.css";

export const CourseContentContainerMobile = ({ handleContentMenu }) => {
  // const theme = useTheme();
  const params = useParams();
  const moodlev2 = useMoodleV2();
  const navigate = useNavigate();
  // const auth = useAuth();
  const [group, setGroup] = useState(null);

  useEffect(() => {
    if (params.id === undefined) {
      navigate("/listcourses");
    } else {
      moodlev2
        .getCourseContentByID(params.id, true, window.location.search)
        .catch((err) => {
          console.log(err);
        });
    }

    return () => {
      moodlev2.setSelectedView(0);
    }; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    moodlev2.getCourseUserList(params.id).then((res) => {
      setGroup(res);
    });
    //eslint-disable-next-line
  }, [params.id]);
  return (
    <div className="col-12 content-mobile">
      <div className="col-12 container-mobile">
        <CourseTitle moodleV2={moodlev2} params={params} />
        <TabsRow />
        {group && <GroupRow group={group} />}
        <ContentPickerContainer>
          {moodlev2.currentModules &&
            moodlev2.currentModules.map((module, idx) => {
              return (
                <CourseContentModuleRowComponent
                  module={module}
                  key={module.id}
                  moodle={moodlev2}
                  idx={idx}
                  handleContentMenu={handleContentMenu}
                />
              );
            })}
          {!moodlev2.currentModules && <SkeletonModules />}
        </ContentPickerContainer>
      </div>
    </div>
  );
};

const CourseTitle = ({ moodleV2, params }) => {
  const [title, setTitle] = useState("titulo");
  useEffect(() => {
    if (moodleV2 && moodleV2.courses) {
      let course_title = moodleV2.courses.filter(
        (e) => e.id === Number(params.id)
      );
      setTitle(course_title[0].displayname);
    }
    if (moodleV2 && !moodleV2.courses) {
      moodleV2.getCourses();
    }
    //eslint-disable-next-line
  }, [moodleV2?.courses]);

  return <div className="row mx-0 course-title-row">{title}</div>;
};

const TabsRow = () => {
  const moodle = useMoodleV2();
  const theme = useTheme();

  React.useEffect(() => {
    console.log("moodle.humhub: ", moodle.humhub);
  }, [moodle.humhub]);

  return (
    <div className="row mx-0 tabs-row">
      <button
        type="button"
        className={`${
          moodle.humhub
            ? "col-md-3 btn btn-primary btn-tab-course"
            : "col-md-4 btn btn-primary btn-tab-course-2"
        }`}
        onClick={() => moodle.contentSelected()}
        style={{
          fontSize: 11,
          backgroundColor:
            moodle.selectedView === 0
              ? theme.mode === "light"
                ? "#69b7cb"
                : "#FFCC00"
              : "#15171f",
          color: moodle.selectedView === 0 ? "#fff" : "#b2b2b2",
        }}
      >
        Contenido
      </button>
      <button
        type="button"
        className={`${
          moodle.humhub
            ? "col-md-3 btn btn-primary btn-tab-course"
            : "col-md-4 btn btn-primary btn-tab-course-2"
        }`}
        onClick={() => moodle.programSelected()}
        style={{
          fontSize: 11,
          backgroundColor:
            moodle.selectedView === 2
              ? theme.mode === "light"
                ? "#b2b2b2"
                : "#FFCC00"
              : "#15171f",
          color: moodle.selectedView === 2 ? "#fff" : "#b2b2b2",
        }}
      >
        Programa
      </button>
      {moodle.humhub && (
        <button
          type="button"
          className="col-md-3 btn btn-primary btn-tab-course"
          onClick={() => moodle.humhubSelected()}
          style={{
            backgroundColor:
              moodle.selectedView === 1
                ? theme.mode === "light"
                  ? "#b2b2b2"
                  : "#FFCC00"
                : "#15171f",
            color: moodle.selectedView === 1 ? "#fff" : "#b2b2b2",
          }}
        >
          Comunidad
        </button>
      )}

      <button
        type="button"
        className={`${
          moodle.humhub
            ? "col-md-3 btn btn-primary btn-tab-course"
            : "col-md-4 btn btn-primary btn-tab-course-2"
        }`}
        onClick={() => moodle.onlineSelected()}
        style={{
          fontSize: 11,
          backgroundColor:
            moodle.selectedView === 2
              ? theme.mode === "light"
                ? "#b2b2b2"
                : "#FFCC00"
              : "#15171f",
          color: moodle.selectedView === 2 ? "#fff" : "#b2b2b2",
        }}
      >
        Clases en línea
      </button>
    </div>
  );
};

const GroupRow = ({ group }) => {
  return (
    <div className="row mx-0 group-row">
      <div className="group-text">
        <img className="group-icon" src={groupIcon} alt="group icon" />
        Grupo: {group}
      </div>
    </div>
  );
};

const ContentPickerContainer = ({ children }) => {
  return <div className="content-container">{children}</div>;
};

const SkeletonModules = () => {
  return (
    <>
      {[1, 2, 3, 4, 5].map((k, i) => {
        return <SkeletonModule key={i} />;
      })}
    </>
  );
};

const SkeletonModule = () => {
  return (
    <div
      style={{ display: "flex", padding: "1.2rem 2rem", alignItems: "center" }}
    >
      <Skeleton
        baseColor="#adb5bd"
        style={{
          opacity: 0.1,
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          marginRight: "1rem",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.1,
            width: "250px",
            height: "12px",
            marginLeft: "5px",
            borderRadius: "10px",
          }}
        />
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.1,
            width: "220px",
            height: "12px",
            marginLeft: "5px",
            borderRadius: "10px",
          }}
        />
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.1,
            width: "200px",
            height: "12px",
            marginLeft: "5px",
            borderRadius: "10px",
          }}
        />
      </div>
    </div>
  );
};
