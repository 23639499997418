import { useEffect, useRef, useState } from "react";
import { ICONS } from "../../utils/iconsv2";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./index.css";
import { MOODLE_STRINGS, TIPDDY_MODULES } from "../../services/tipddy-v2/state";
import meet from "../../assets/online/meet.png";
import teams from "../../assets/online/teams.png";
import zoom from "../../assets/online/zoom.png";
import { useTheme } from "../../contexts/ThemeContext";

const onlineicons = {
  meet: meet,
  teams: teams,
  zoom: zoom,
};

export const ResourceLabelComponent = ({
  module,
  resource,
  moodle,
  container,
  idx,
  handleContentMenuMobile = null,
}) => {
  const theme = useTheme();
  const moduleRef = useRef(null);
  const [isCurrentResource, setCurrentResource] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  // const [refreshHandler, setRefreshHandler] = useState(0);

  useEffect(() => {
    if (resource.completiondata && resource.completiondata.state === 0) {
      setIsCompleted(false);
    }

    if (resource.completiondata && resource.completiondata.state !== 0) {
      setIsCompleted(true);
    }
  }, [resource]);

  useEffect(() => {
    // console.log({ resource });
    let isCurrent = resource.id === moodle.currentResourceId;
    setCurrentResource(isCurrent);
    // eslint-disable-next-line
  }, [moodle.currentResourceId]);

  useEffect(() => {
    if (
      resource.tipddytype === "label" &&
      resource.tipddyname === "summary" &&
      resource.tipddyname === "label"
    ) {
      setCurrentResource(true);
    }
  }, [resource]);

  const handleResourceSelected = async () => {
    console.log("handleResource", resource);
    handleContentMenuMobile();
    if (resource.tipddytype === "label" && resource.tipddyname === "summary") {
      return;
    }
    if (resource.tipddytype === MOODLE_STRINGS.PROGRAMS) {
      moodle.setPageEmbed(resource.tipddyurl);
    }
    if (
      resource.tipddytype === MOODLE_STRINGS.ONLINE &&
      moodle.selectedView === 2 &&
      !resource.embed
    ) {
      window.open(resource.onlineurl);
      return;
    }
    if (
      resource.tipddytype === MOODLE_STRINGS.ONLINE &&
      moodle.selectedView === 2 &&
      resource.embed
    ) {
      await moodle.requestPageEmbed({
        pageurl: resource.url,
        module,
        resource,
        course_id: moodle.currentCourseId,
      });
      return;
    }
    if (
      resource.tipddytype === MOODLE_STRINGS.ONLINE &&
      moodle.selectedView !== 2
    ) {
      return;
    }

    if (
      !resource.tipddytype &&
      resource.tipddyname === "url" &&
      resource.contents.length > 0 &&
      resource.contents[0].fileurl !== ""
    ) {
      console.log(resource.contents[0].fileurl);
      window.open(resource.contents[0].fileurl, "_blank");
    }

    /* console.log(resource.url); */
    await moodle.requestPageEmbed({
      pageurl: resource.url,
      module,
      resource,
      course_id: moodle.currentCourseId,
    });
    console.log({ container, isCurrentResource, idx });
    if (isCurrentResource && container.current && idx !== 0) {
      console.log("offset", moduleRef.current.offset);
      container.current.scrollTo({
        top: moduleRef.current.offset,
        behavior: "smooth",
      });
    }
  };
  /*  */
  if (
    resource.tipddytype === MOODLE_STRINGS.PROGRAMS &&
    moodle.selectedView !== 1
  ) {
    return null;
  }

  if (resource.tipddytype === TIPDDY_MODULES.schedule) {
    return null;
  }

  if (resource.tipddyname === "summary") {
    return null;
  }

  if (resource.tipddyname === TIPDDY_MODULES.humhub) {
    return null;
  }

  /*
   * El siguiente condicional es para evitar mostrar recursos ocultos
   * uservisible tiende ser igual a 0, cuando el usuario no pertenece al grupo del curso que puede ver el recurso
   * visible tiende ser igual a 0, cuando el recurso esta oculto (roles como administrador pueden visualizarlo)
   * visibleoncoursepage tiende ser igual a 0, cuando el recurso esta oculto pero disponible
   */
  if (
    !resource.uservisible ||
    resource.visible === 0 ||
    resource.visibleoncoursepage === 0
  ) {
    return null;
  }

  return (
    <div
      ref={moduleRef}
      onClick={handleResourceSelected}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "15px 0",
        cursor: "pointer",
        marginRight: "15px",
        zIndex: 1000,

        opacity:
          (resource.tipddytype === MOODLE_STRINGS.ONLINE &&
            moodle.selectedView !== 0) ||
          (resource.tipddytype === MOODLE_STRINGS.PROGRAMS &&
            moodle.selectedView !== 0)
            ? 1
            : isCurrentResource
            ? 1
            : 0.8,
      }}
    >
      <div style={{ marginRight: "15px" }}>
        {resource.isTracked ? (
          <DoneAllIcon
            sx={{
              color: "#fff",
              opacity: isCompleted ? 1 : 0.2,
              fontSize: 22,
            }}
          />
        ) : (
          <VisibilityIcon sx={{ color: "#fff", fontSize: 22 }} />
        )}
      </div>
      <div>
        <p
          className="resource-container"
          style={{
            color: isCurrentResource
              ? theme.mode === "dark"
                ? "#fff"
                : "#fff"
              : "#fff",
            margin: 0,
            marginLeft: resource.indent * 15,

            fontSize:
              resource.tipddytype === MOODLE_STRINGS.ONLINE ||
              resource.tipddytype === MOODLE_STRINGS.PROGRAMS
                ? "14px"
                : isCurrentResource
                ? "14px"
                : "13px",
            fontWeight: isCurrentResource ? "bolder" : "unset",
          }}
        >
          {resource.name}
        </p>
        {resource.tipddytype === MOODLE_STRINGS.ONLINE &&
          moodle.selectedView === 0 && (
            <p
              className="resource-container"
              style={{
                color: "#fff",
                margin: 0,
                fontSize: "10px",
              }}
            >
              Recurso disponible en clases en linea
            </p>
          )}
      </div>

      {moodle.selectedView !== 2 && (
        <div style={{ marginLeft: "auto" }}>{ICONS[resource.tipddyname]}</div>
      )}
      {moodle.selectedView === 2 && resource.tipddyname && (
        <div style={{ marginLeft: "auto" }}>
          <img
            alt="...icon"
            src={onlineicons[`${resource.tipddyname}`]}
            width="18px"
            style={{ marginRight: "12px", marginBottom: 3 }}
          />
        </div>
      )}
    </div>
  );
};
