import "./spinner.css";
import image from "../../assets/logo.png";

export const NativeSpinner = (props) => {
  return (
    <div
      style={{
        backgroundColor: "#082f49",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
      }}
    >
      <div className="sk-fading-circle">
        <div className="sk-circle1 sk-circle"></div>
        <div className="sk-circle2 sk-circle"></div>
        <div className="sk-circle3 sk-circle"></div>
        <div className="sk-circle4 sk-circle"></div>
        <div className="sk-circle5 sk-circle"></div>
        <div className="sk-circle6 sk-circle"></div>
        <div className="sk-circle7 sk-circle"></div>
        <div className="sk-circle8 sk-circle"></div>
        <div className="sk-circle9 sk-circle"></div>
        <div className="sk-circle10 sk-circle"></div>
        <div className="sk-circle11 sk-circle"></div>
        <div className="sk-circle12 sk-circle"></div>
        <p
          style={{
            marginTop: 100,
            width: 200,
            color: "#fff",
            fontSize: 16,
            transform: "translateX(-45px)",
            opacity: 0.6,
          }}
        >
          Cargando credenciales...
        </p>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "99%",
          margin: 0,
          padding: 0,
          opacity: 0.6,
        }}
      >
        <img src={image} width={75} alt="..." />
        <p style={{ color: "#fff", fontSize: 18 }}>Powered by Tippdy</p>
      </div>
    </div>
  );
};
