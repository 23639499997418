import React, { useEffect, useState } from "react";
import { useConfig } from "./config";

export const ThemeContext = React.createContext(null);

export const ThemeProvider = ({ children }) => {
  const { config } = useConfig();
  const [palette, setPalette] = useState({
    dark_mode: {
      primary: "rgb(34, 34, 34)",
      secondary: "rgb(71 93 116)",
    },
    primary: "#f9be14",
    seconday: "#2d4662",
    tertiary: "#18232d",
    nav_light: "#16232c",
    nav_dark: "#222222",
    day_mode_btn: "#fdbf00",
    dark_mode_btn: "rgb(71 93 116)",
    title_day: "#16232c",
    title_dark: "#fff",
    text_day: "#000",
    text_dark: "#a19b9c",
  });
  const [mode, setMode] = React.useState(
    localStorage.getItem("darkMode") || "light"
  );
  const [layout, setLayout] = useState(true);

  function handleContentLayout() {
    setLayout(!layout);
  }

  useEffect(() => {
    if (config) {
      setPalette({
        ...palette,
        primary: config.config.theme.colors.primary,
        secondary: config.config.theme.colors.secondary,
        tertiary: config.config.theme.colors.tertiary,
        nav_light: config.config.theme.colors.primary,
      });
    }

    // eslint-disable-next-line
  }, [config]);

  React.useEffect(() => {
    if (mode === "dark" && window.location.pathname !== "/") {
      document.querySelector("#root").style.backgroundColor =
        palette.dark_mode.primary;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = {
    darkmodebtn: {
      backgroundColor:
        mode === "light" ? palette.day_mode_btn : palette.dark_mode_btn,
    },
    darkmodebtn2: {
      backgroundColor:
        mode === "light" ? palette.day_mode_btn : palette.dark_mode.secondary,
    },
    nav: {
      backgroundColor: mode === "light" ? palette.nav_light : palette.nav_dark,
    },
    title_text: {
      color: mode === "light" ? palette.title_day : palette.title_dark,
    },
    text: {
      color: mode === "light" ? palette.text_day : palette.text_dark,
    },
    background_secondary: {
      backgroundColor:
        mode === "light" ? "#f5f5f5" : palette.dark_mode.secondary,
    },
    background_primary: {
      backgroundColor: mode === "light" ? "#f5f5f5" : palette.dark_mode.primary,
    },
    bg_course_group: {
      backgroundColor:
        mode === "light" ? "rgba(0, 0, 0, 0.05)" : palette.dark_mode.primary,
    },
    box_temas: {
      backgroundColor:
        mode === "light" ? "#f8f8f8" : palette.dark_mode.secondary,
    },
  };

  let actions = {
    handleDarkMode: function () {
      if (mode === "light") {
        setMode("dark");
        localStorage.setItem("darkMode", "dark");
        document.querySelector("#root").style.backgroundColor =
          palette.dark_mode.secondary;
      } else {
        setMode("light");
        localStorage.setItem("darkMode", "light");
        document.querySelector("#root").style.backgroundColor = "#f5f5f5";
      }
    },
  };

  let value = {
    styles,
    actions,
    mode,
    palette,
    layout,
    handleContentLayout,
  };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
