import { useEffect, useRef, useState } from "react";
import { Suspense } from "./suspense";
import { useAuth } from "../../../contexts/AuthContext";
import back from "./arrow-left.svg";
import dots from "./three-dots.svg";
import { messagesWithDate } from "../utils";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useMoodleV2 } from "../../../contexts/moodle";

export const Conversation = ({
  messages,
  selected,
  setMessages,
  chatid,
  showTabs,
  setView,
  talker,
  talkingto,
  auth,
  setCurrentChatId,
  setChats,
  currentChatData,
}) => {
  const conversationContainerElement = useRef(null);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true);
  let isSelectedView = selected === "conversation";
  let moodle = useMoodleV2();

  useEffect(() => {
    if (isSelectedView && !messages && chatid) {
      showTabs(false);
      setTimeout(() => {
        moodle
          .getMessagesFromConversation(chatid)
          .then((res) => {
            talkingto(currentChatData.members[0]);
            setMessages(messagesWithDate(res.messages));
            setLoading(false);
            moodle
              .markConversationAsRead({ conversationid: chatid })
              .then((res) => {})
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => console.log(err));
      }, 2000);
    }

    if (isSelectedView && !messages && talker) {
      showTabs(false);
      setTimeout(() => {
        moodle
          .getConversationFromUserId(talker.id)
          .then((res) => {
            if (res.messages) {
              setMessages(messagesWithDate(res.messages));
              setCurrentChatId(res.id);
              setLoading(false);
            }
            setLoading(false);
          })
          .catch((err) => console.log(err));
      }, 2000);
    }

    return () => {
      setLoading(true);
    };
    // eslint-disable-next-line
  }, [isSelectedView]);

  const handleNewMessage = ({ target: { value } }) => {
    setNewMessage(value);
  };

  const handleSubmitNewMessage = async () => {
    let newmessages = instantMessageFactory();
    setNewMessage("");
    moodle
      .sendMessagetoConversation(chatid, newMessage)
      .then((res) => {
        if (res.exception) {
          throw new Error(res.exception);
        }
        moodle
          .getMessagesFromConversation(chatid)
          .then((res) => {
            setMessages(messagesWithDate(res.messages));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        lastMessageException(newmessages);
        console.log(err);
      });
  };

  const sendInstanMessage = () => {
    let newmessages = instantMessageFactory();
    setNewMessage("");
    moodle
      .sendInstantMessage({ message: newMessage, otheruserid: talker.id })
      .then((res) => {
        // console.log({ res });
        if (res.exception) {
          throw new Error(res.exception);
        }
        setCurrentChatId(res[0].conversationid);
        moodle
          .getMessagesFromConversation(res[0].conversationid)
          .then((res) => {
            setMessages(messagesWithDate(res.messages));
            setChats(null);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        lastMessageException(newmessages);
        console.log(err);
      });
  };

  const handleBackNavigation = () => {
    currentChatData && currentChatData.unreadcount && setChats(null);
    showTabs(true);
    setView(0);
  };

  const handleUserOptions = () => {
    showTabs(false);
    setView(3);
  };

  function lastMessageException(newmessage) {
    let currentMessages = messages || [];
    let errorMessage = {
      useridfrom: auth.user.id,
      text: `<p>${newmessage}</p>`,
      timecreated: Math.floor(Date.now() / 1000),
      hasError: true,
    };
    let newMessages = [...currentMessages, errorMessage];
    setMessages(messagesWithDate(newMessages));
  }

  function instantMessageFactory() {
    let newMsg = {
      useridfrom: auth.user.id,
      text: `<p>${newMessage}</p>`,
      timecreated: Math.floor(Date.now() / 1000),
    };
    console.log({ messages });

    let currentmessages = messages || [];
    let newmessages = [...currentmessages, newMsg];
    setMessages(messagesWithDate(newmessages));
    return newMessage;
  }

  if (isSelectedView && talker && !loading) {
    return (
      <>
        <div
          style={{
            padding: "2rem",
            display: "flex",
            alignItems: "center",
            gap: 15,
          }}
        >
          <img
            src={back}
            alt="..."
            style={{ cursor: "pointer" }}
            onClick={handleBackNavigation}
          />
          <img
            style={{ borderRadius: "50%" }}
            src={talker?.profileimageurlsmall}
            alt="..."
          />
          <p style={{ marginRight: "5rem" }}>{talker?.fullname}</p>
          <img
            src={dots}
            style={{ cursor: "pointer" }}
            onClick={handleUserOptions}
            alt="..."
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
          <div
            ref={conversationContainerElement}
            style={{
              maxHeight: "70vh",
              overflowY: "scroll",
              padding: ".5rem 0",
            }}
          >
            {messages &&
              messages.length > 0 &&
              messages.map((msg, idx) => (
                <MessageItem
                  key={idx}
                  m={msg}
                  lastElementIndex={messages.length - 1}
                  index={idx}
                  container={conversationContainerElement}
                />
              ))}
          </div>
          <div style={{ margin: ".3rem 1rem", marginTop: "auto" }}>
            <textarea
              wrap="hard"
              className="form-control"
              maxLength={100}
              style={{ height: 70 }}
              value={newMessage}
              onChange={handleNewMessage}
              on
            />
            <button
              onClick={chatid ? handleSubmitNewMessage : sendInstanMessage}
              className="btn btn-primary bot-generico my-3"
              style={{ padding: ".3rem 2rem", marginLeft: 4 }}
            >
              Enviar
            </button>
          </div>
        </div>
      </>
    );
  }

  if (isSelectedView && loading) {
    return <Suspense />;
  }

  return null;
};

const MessageItem = ({ m, lastElementIndex, index, container }) => {
  const messageElement = useRef(null);
  const auth = useAuth();

  useEffect(() => {
    if (index === lastElementIndex && messageElement.current) {
      container.current.scrollTo({ top: messageElement.current.offsetTop });
    }
    // eslint-disable-next-line
  }, []);

  const timeCreated = (time) => {
    let date = new Date(time * 1000);
    let hour = date.getHours();
    let minutes = date.getMinutes();

    return `${hour}:${minutes}`;
  };

  let createdAt = timeCreated(m.timecreated);

  let isMine = auth.user.id === m.useridfrom;

  const styles = {
    textcontainer: {
      backgroundColor: isMine ? "#2c8244" : "#1c737c",
      margin: isMine ? "0.4rem 1rem 0.4rem auto" : "0.4rem 1rem",
      borderRadius: !isMine ? "0 7px 7px 7px" : "7px 0px 7px 7px",
      padding: ".6rem 1rem",
      color: "#fff",
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      opacity: m?.hasError ? 0.5 : 1,
    },
    time: {
      fontSize: 10,
      marginLeft: "1.2rem",
      marginTop: ".5rem",
    },
  };

  return (
    <>
      {m.hastime && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "3rem 0",
            opacity: 0.5,
          }}
        >
          <p style={{ fontSize: "14px" }}>{m.hastime.data}</p>
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: m.hasError ? "20px" : "0",
        }}
      >
        {m?.hasError && (
          <div>
            <ErrorOutlineIcon sx={{ color: "#f44336", fontSize: 20 }} />
          </div>
        )}
        <div style={styles.textcontainer} ref={messageElement}>
          <span dangerouslySetInnerHTML={{ __html: m.text }}></span>
          <p style={styles.time}>{createdAt}</p>
        </div>
      </div>
    </>
  );
};
