import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { useMoodleV2 } from "../../contexts/moodle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export const NavigationButtons = () => {
  const theme = useTheme();
  const moodlev2 = useMoodleV2();

  const styles = {};

  styles.container = {
    // position: "absolute",
    // bottom: 42,
    // right: 10,
    // zIndex: 1000,
    width: "fit-content",
    display: "flex",
    backgroundColor: "transparent",
    padding: "0rem",
  };

  styles.innerContainer = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #b2b2b2",
    borderRadius: 9,
  };

  styles.prevButton = {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    height: 42,
    width: 50,
    backgroundColor: theme.mode === "dark" ? "#fdbf00" : "#fdbf00",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1.2rem",
    margin: "0",
  };

  styles.nextButton = {
    backgroundColor:
      // theme.mode === "dark" ? "#999999" : theme.palette.secondary,
      theme.palette.secondary,
    color: theme.mode === "dark" ? "#fff" : "#fff",
    height: 42,
    width: "100%",
    padding: "1.2rem",
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0",
    cursor:
      moodlev2.nextResourceName === "Fin del curso" ? "default" : "pointer",
  };

  styles.arrowRightIcon = { transform: "translateY(1px)" };

  styles.arrowLeftIcon = { color: theme.mode === "dark" ? "#000" : "#000" };

  const handleNext = async () => {
    if (moodlev2.nextResourceName === "Fin del curso") {
      return;
    } else {
      await moodlev2.navigateNext();
    }
  };

  const handlePrev = async () => {
    await moodlev2.navigatePrev();
  };

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.prevButton} onClick={handlePrev}>
          <KeyboardArrowLeftIcon sx={styles.arrowLeftIcon} />
        </div>
        <div style={styles.nextButton} onClick={handleNext}>
          <p className="m-0">
            {window.screen.width <= 1024
              ? /* ? moodlev2.nextResourceName.substring(0, 30) + "..." */
                moodlev2.nextResourceName === "Fin del curso"
                ? moodlev2.nextResourceName
                : "Siguiente"
              : moodlev2.nextResourceName}
          </p>
          {moodlev2.nextResourceName === "Fin del curso" ? (
            <></>
          ) : (
            <KeyboardArrowRightIcon sx={styles.arrowRightIcon} />
          )}
        </div>
      </div>
    </div>
  );
};
