import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const Suspense = () => {
  let styles = {};

  let height = "5rem";

  let reactWidth = "235px";

  styles.rowStart = {
    display: "flex",
    justifyContent: "start",
    marginTop: ".8rem",
    padding: "0 0 0 1.4rem",
  };

  styles.rowEnd = {
    display: "flex",
    justifyContent: "end",
    marginTop: ".8rem",
    padding: "0 1.4rem 0 0",
  };

  return (
    <>
      <div style={styles.rowStart}>
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: reactWidth,
            height: height,
            borderRadius: "12px",
          }}
        />
      </div>
      <div style={styles.rowEnd}>
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: reactWidth,
            height: height,
            borderRadius: "12px",
          }}
        />
      </div>
      <div style={styles.rowStart}>
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: reactWidth,
            height: height,
            borderRadius: "12px",
          }}
        />
      </div>
      <div style={styles.rowEnd}>
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: reactWidth,
            height: height,
            borderRadius: "12px",
          }}
        />
      </div>
      <div style={styles.rowStart}>
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: reactWidth,
            height: height,
            borderRadius: "12px",
          }}
        />
      </div>
      <div style={styles.rowEnd}>
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: reactWidth,
            height: height,
            borderRadius: "12px",
          }}
        />
      </div>
      <div style={styles.rowStart}>
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: reactWidth,
            height: height,
            borderRadius: "12px",
          }}
        />
      </div>
      <div style={styles.rowEnd}>
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: reactWidth,
            height: height,
            borderRadius: "12px",
          }}
        />
      </div>
    </>
  );
};
