import React from "react";
import { defaultHandleChange } from "../../utils/handler";
import { DEFAULT_CHANGE_PASSWORD_CREDENTIALS } from "../../utils/state";
import { Snackbar } from "../../components/Snackbar";
import {
  DEFAULT_SNACKBAR,
  ERROR_CREDENTIALS,
  ERROR_EMPTY_CREDENTIALS,
  SUCCESS_PASSWORD_CHANGE,
} from "../../utils/snackbar";
import { ChangePasswordForm } from "../../components/LoginForm/ChangePasswordForm";
import { useValidToken } from "../../hooks/useValidToken";
import Wrapper from "../../components/LoginForm/Wrapper";
import { useBackground } from "../../hooks/useBackground";
import { fetcher } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../../contexts/config";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = React.useState(
    DEFAULT_CHANGE_PASSWORD_CREDENTIALS
  );
  const [loading, setLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(DEFAULT_SNACKBAR);
  const { data, isValid } = useValidToken(window.location.search);
  const { config } = useConfig();
  const URL = `${config.baseURL}tipddy/admin/iapp/services/restore-password.php/user/changepassword`;
  const headers = { "Content-Type": "appplication/json" };

  useBackground();

  React.useEffect(() => {
    let bg_img = config.config.theme.background_login;
    document.body.classList.add("imagen2");
    if (localStorage.getItem("token")) navigate("/listcourses");

    if (bg_img) {
      document.body.style.backgroundImage = `url(${bg_img})`;
    }

    return () => {
      document.body.style.backgroundImage = ``;
      document.body.classList.remove("imagen2");
    };
    // eslint-disable-next-line
  }, []);

  const handleSnackbar = (snack) => {
    setSnackbar(snack);
    setTimeout(() => {
      setSnackbar(DEFAULT_SNACKBAR);
    }, 2000);
  };

  const clean = () => {
    setCredentials(DEFAULT_CHANGE_PASSWORD_CREDENTIALS);
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isEmpty = Object.values(credentials).some((value) => value === "");
    if (isEmpty) {
      handleSnackbar(ERROR_EMPTY_CREDENTIALS);
      return;
    }
    if (credentials.password !== credentials.confirm_password) {
      handleSnackbar(ERROR_EMPTY_CREDENTIALS);
      return;
    }
    try {
      setLoading(true);
      await fetcher(URL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          password: credentials.password,
          token: data.setdata.token,
        }),
      });
      handleSnackbar(SUCCESS_PASSWORD_CHANGE);
      clean();
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      console.log(error.message);
      setSnackbar(ERROR_CREDENTIALS);
      setLoading(false);
      setTimeout(() => {
        setSnackbar(DEFAULT_SNACKBAR);
      }, 2000);
    }
  };

  const handleChange = (e) =>
    defaultHandleChange(e, credentials, setCredentials);

  if (!isValid) {
    return (
      <Wrapper>
        <p className="text-white text-center my-5">Token ya expiró</p>
      </Wrapper>
    );
  }

  return (
    <>
      <Wrapper>
        <ChangePasswordForm
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          credentials={credentials}
          loading={loading}
        />
      </Wrapper>
      <Snackbar
        severity={snackbar.severity}
        visible={snackbar.visible}
        message={snackbar.message}
      />
    </>
  );
};
export default ChangePassword;
