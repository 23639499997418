import React from "react";
import { useNavigate } from "react-router-dom";

export const ProgressRowItem = ({ course, grade }) => {
  const navigation = useNavigate();
  const [data /* setData */] = React.useState({
    name: course.displayname,
    start: new Date(course.startdate * 1000).toLocaleDateString(),
    end:
      course.enddate !== 0
        ? new Date(course.enddate * 1000).toLocaleDateString()
        : "Sin fecha de termino",
  });

  const handleDetailNavigation = () =>
    navigation(`/listcourses/progress/${course.id}`, {
      state: { name: data.name },
    });

  return (
    <>
      <tr>
        <th onClick={handleDetailNavigation} scope="row" className="title-row">
          {data.name}
        </th>
        <td>{grade}</td>
        <td>{data.start}</td>
        <td>{data.end}</td>
        <td>Descargar</td>
        <td>Ver respuestas</td>
      </tr>
    </>
  );
};
