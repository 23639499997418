/* Crear Un formulario html con los siguientes inputs en name y en id y en value */
import { /* SENCE_DEV, */ SENCE_PROD } from "../state";

export const FormSence = () => {
  return (
    <form id="form-sence" hidden method="POST" action={SENCE_PROD.URL_LOGIN}>
      <input id="RutOtec" defaultValue={SENCE_PROD.RUT_OTEC} name="RutOtec" />
      <input id="Token" defaultValue={SENCE_PROD.TOKEN} name="Token" />
      <input id="CodSence" defaultValue={""} name="CodSence" />
      <input id="CodigoCurso" defaultValue={""} name="CodigoCurso" />
      <input
        id="LineaCapacitacion"
        defaultValue={SENCE_PROD.LINEA_CAPACITACION}
        name="LineaCapacitacion"
      />
      <input id="RunAlumno" defaultValue={""} name="RunAlumno" />
      <input id="IdSesionAlumno" defaultValue={""} name="IdSesionAlumno" />
      <input id="IdSesionSence" defaultValue={""} name="IdSesionSence" />
      <input
        id="UrlRetoma"
        /*   defaultValue={SENCE_DEV.URL_RETURN_LOGIN} */
        defaultValue={SENCE_PROD.URL_RETURN_LOGIN}
        name="UrlRetoma"
      />
      {/*  <input id="UrlError" defaultValue={SENCE_DEV.URL_LOGIN_ERROR} name="UrlError" /> */}
      {
        <input
          id="UrlError"
          defaultValue={SENCE_PROD.URL_LOGIN_ERROR}
          name="UrlError"
        />
      }
    </form>
  );
};
