import axios from "axios";

export const recentlyAccessedItems = async ({ token, baseURL }) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=block_recentlyaccesseditems_get_recent_items&limit=0`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
