import React from 'react'
import './styles.css'

export const ProgressGroup = ({children}) => {
  return (
    <div className="group-chart-container" style={{display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridTemplateRows: 'auto',
      gridColumnGap: '0px',
      gridRowGap: '0px', /* maxWidth:'100vw', */ width: '100%', margin: '2rem 0 10rem 0', /* overflowX: 'scroll' */}}>
      {children}
    </div>
  )
}
