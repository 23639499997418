import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
// import modo_dia from "./assets/modoo-dia.png";
// import modo_noche from "./assets/modo-noche.png";
// import ico_cel from "./assets/ico-cel.png";
import menu from "./assets/list.svg";
import watch from "./assets/stopwatch-fill.svg";
import React from "react";
import { CustomDialog } from "../../components/Dialog";
import { useTheme } from "../../contexts/ThemeContext";
import { SenceValidation } from "../../services/sence";
import { useTimer } from "../Timer";
import { THEME_CONTEXT } from "../../utils/theme";
import { LIMIT_CRON_AVG } from "../../utils/state";
import { Snackbar } from "../../components/Snackbar";
import { DEFAULT_SNACKBAR, SENCE_WARNING } from "../../utils/snackbar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LogoutIcon from "@mui/icons-material/Logout";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import { useMoodleV2 } from "../../contexts/moodle";
import WifiOffIcon from "@mui/icons-material/WifiOff";
// import WifiIcon from "@mui/icons-material/Wifi";
import { useConfig } from "../../contexts/config";
import { /* Icon, */ IconButton } from "@mui/material";
import { Notifications } from "../Notifications";
import { NavigationButtons } from "../NavigationButtons";
import { CourseContentContainerMobile } from "../CourseContentContainer";

/* to work on */
export const Navbar = (
  /* {type} */ {
    handleCalendar,
    layout,
    handleContentLayout,
    eventsCount,
    sidebar,
    toggleSidebar,
    noticount,
    notifications,
    openNotifications,
    opennotif,
    handleDarkMode,
  }
) => {
  const { config } = useConfig();
  const navigate = useNavigate();
  const moodle = useMoodleV2();
  const { time, limit } = useTimer();
  const auth = useAuth();
  const theme = useTheme();
  const navref = React.useRef(null);
  const isSence = localStorage.getItem("sence_session");
  const [snackbar, setSnackbar] = React.useState(DEFAULT_SNACKBAR);
  const [open, setOpen] = React.useState(false);
  // const icon = theme.mode === "light" ? modo_dia : modo_noche;
  const [hoverBell, setHoverBell] = React.useState(false);
  const [hoverCalendar, setHoverCalendar] = React.useState(false);
  // const [eventsCount, setEventsCount] = React.useState(null);
  const [contentMenu, toggleContentMenu] = React.useState(false);

  const [isOnline, setIsOnline] = React.useState(navigator.onLine);

  React.useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  function handleOnline() {
    setIsOnline(true);
  }

  function handleOffline() {
    setIsOnline(false);
  }

  // React.useEffect(() => {
  //   moodle &&
  //     auth.user &&
  //     moodle
  //       .fetchNotifications()
  //       .then((res) => {
  //         setNotifications(res.notifications);
  //         setNotiCount(res.unreadcount);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });

  //   // eslint-disable-next-line
  // }, [auth.user]);

  // React.useEffect(() => {
  //   moodle &&
  //     auth.user &&
  //     moodle
  //       .fetchEvents()
  //       .then((res) => {
  //         setEventsCount(res.events?.length);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  // console.log({ eventsCount });
  // eslint-disable-next-line
  // }, [eventsCount]);

  React.useEffect(() => {
    if (limit > LIMIT_CRON_AVG) {
      handleSenceLogout();
      localStorage.removeItem("last_login_sence");
      localStorage.removeItem("sence_session");
      localStorage.removeItem("current_course");
      localStorage.removeItem("idSence");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const handleLogout = () => {
    setOpen(true);
  };
  const handleLogoutCompleted = () => {
    setOpen(false);
    auth.logout(moodle);
    navigate("/");
  };

  const handleSenceLogout = () => {
    let id = localStorage.getItem("idSence");
    if (!id) {
      return;
    }
    let sence = JSON.parse(localStorage.getItem("sence_session"));
    let nru = localStorage.getItem("nru");
    let validation = {
      idSence: id,
      auth,
      session: sence,
      isLogin: false,
      nru,
    };
    /*     console.log(sence) */
    SenceValidation(validation);
  };

  let disabled = {
    pointerEvents: isSence ? "none" : "",
    opacity: isSence ? 0.5 : 1,
  };

  let timer = null;

  const handleSenceWarning = () => {
    if (!isSence) {
      return;
    }
    if (timer != null) {
      clearTimeout(timer);
      timer = null;
      return;
    } else {
      timer = setTimeout(closeSenceWarning, 2000);
      setSnackbar(SENCE_WARNING);
      return;
    }
  };

  const closeSenceWarning = () => {
    setTimeout(() => {
      setSnackbar(DEFAULT_SNACKBAR);
    }, 2000);
  };

  const handleSidebar = () => {
    let { style } = document.querySelector(".sidebar-container");
    if (!sidebar) {
      style.left = "0";
      return toggleSidebar(!sidebar);
    }

    style.left = "-350px";
    toggleSidebar(!sidebar);
  };

  const handleContentMenu = () => {
    let { style } = document.querySelector(".content-mobile");
    if (!contentMenu) {
      style.top = "102%";
      return toggleContentMenu(!contentMenu);
    }

    style.top = "-5000px";
    toggleContentMenu(!contentMenu);
  };

  return (
    <nav ref={navref} style={theme.styles.nav}>
      <div
        className="nav_block"
        onMouseEnter={handleSenceWarning}
        onMouseLeave={handleSenceWarning}
      >
        <div className="logo-desk">
          <img
            alt="img"
            src={config.config.theme.logo}
            width={150}
            style={{ transform: "translateY(-5px)" }}
            onClick={() => navigate("/listcourses")}
          />
        </div>
        <div className="logo-mob">
          <img
            alt="img"
            src={
              config.config.theme.logo_mob
                ? config.config.theme.logo_mob
                : require("./assets/casa.png")
            }
            width={150}
            style={{ transform: "translateY(-5px)" }}
            onClick={() => navigate("/listcourses")}
          />
        </div>
        <div className="list">
          <ul className="navlist" datatoggle="tooltip">
            <Link to={"/listcourses"} style={disabled}>
              <li className="list-item">Inicio</li>
            </Link>
            <Link to={"/listcourses/progress"} style={disabled}>
              <li className="list-item">Historial</li>
            </Link>
          </ul>
        </div>
      </div>
      <div className="nav_block">
        <div
          className="row menu"
          style={{
            justifyContent: window.location.pathname.includes("detailcourse")
              ? "flex-start"
              : "flex-end",
          }}
        >
          <div className="col-3 col-md-3">
            <img
              src={menu}
              alt="..."
              className="burger"
              onClick={handleSidebar}
            />
          </div>
        </div>
        {isSence && (
          <div
            className="student_profile stopwatch"
            style={{
              cursor: "pointer",
              justifyContent: "space-between",
              backgroundColor: THEME_CONTEXT.DAY_MODE_BTN,
              borderRadius: "15px",
              margin: "0",
              padding: "7px 10px",
              ...theme.styles.darkmodebtn,
            }}
            onClick={handleSenceLogout}
          >
            <img src={watch} alt="..." />
            <p>{time}</p>
            <p>Cerrar Sence</p>
          </div>
        )}

        <div className="wifi-icon">
          {!isOnline && (
            <>
              <WifiOffIcon
                sx={{
                  color: "#fff",
                  fontSize: 14,
                  transition: ".3s",
                  marginRight: ".5rem",
                }}
              />
              Sin conexión
            </>
          )}
        </div>
        <div className="nav-course-desktop">
          {window.location.pathname.includes("detailcourse") && (
            <NavigationButtons />
          )}
        </div>

        <div
          onMouseEnter={() => setHoverCalendar(true)}
          onMouseLeave={() => setHoverCalendar(false)}
          className="calendar-icon"
          style={{
            marginRight: 5,
            position: "relative",
            cursor: "pointer",
          }}
        >
          {eventsCount > 0 && (
            <div
              style={{
                position: "absolute",
                top: "-7px",
                right: "-17px",
                backgroundColor: hoverCalendar ? "#c01212" : "red",
                color: "#fff",
                height: 20,
                width: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                transition: ".3s",
              }}
            >
              <p>{eventsCount}</p>
            </div>
          )}
          <CalendarMonthIcon
            onClick={handleCalendar}
            sx={{
              color: hoverCalendar ? "#b2b2b2" : "#fff",
              fontSize: 28,
              transition: ".3s",
            }}
          />
        </div>

        <div
          onMouseEnter={() => setHoverBell(true)}
          onMouseLeave={() => setHoverBell(false)}
          style={{ marginRight: 5, position: "relative", cursor: "pointer" }}
          className="noti-icon"
        >
          <Notifications
            noticount={noticount}
            notifications={notifications}
            hoverBell={hoverBell}
            opennotif={opennotif}
            openNotifications={openNotifications}
          />
        </div>

        {window.location.pathname.includes("detailcourse") && (
          <div
            className="student_profile menu-handler-button"
            style={{
              margin: 0,
              transform: layout ? "rotate(180deg)" : "rotate(0deg)",
            }}
          >
            <IconButton onClick={handleContentLayout} sx={{ padding: "5px" }}>
              {layout ? (
                <MenuOpenIcon
                  sx={{
                    color: theme.mode === "light" ? "#fff" : "#fff",
                    fontSize: 35,
                  }}
                />
              ) : (
                <MenuOpenIcon
                  sx={{
                    color: theme.mode === "light" ? "#fff" : "#fff",
                    fontSize: 35,
                  }}
                />
              )}
            </IconButton>
          </div>
        )}
        <div className="student_profile">
          <p>{auth && auth.user?.fullname}</p>
          <img
            alt="img"
            src={auth.user?.profileimageurlsmall}
            style={{ borderRadius: "50%" }}
          />
        </div>
        <div
          className="modo_dia"
          onClick={handleDarkMode}
          style={theme.styles.darkmodebtn}
        >
          {theme.mode === "light" ? (
            <LightModeIcon sx={{ fontSize: 22 }} />
          ) : (
            <DarkModeIcon sx={{ fontSize: 22 }} />
          )}
        </div>
        <div className="logout" onClick={handleLogout}>
          <LogoutIcon
            sx={{
              fontSize: 30,
              color: "#fff",
              /*        color: theme.mode === "light" ? "#666666" : "#fff", */
            }}
          />
        </div>
      </div>
      {window.location.pathname.includes("detailcourse") && (
        <div className="nav-course-mob">
          {/* <img
            src={FormatListBulletedIcon}
            alt="..."
            className="burger"
            onClick={handleContentMenu}
          /> */}
          <FormatListBulletedIcon
            onClick={handleContentMenu}
            sx={{ color: "#fff", width: "32px", height: "32px" }}
          />
          <NavigationButtons />
          {auth.user && (
            <CourseContentContainerMobile
              handleContentMenu={handleContentMenu}
            />
          )}
        </div>
      )}
      <Snackbar
        message={snackbar.message}
        severity={snackbar.severity}
        visible={snackbar.visible}
      />
      <CustomDialog
        open={open}
        title={"Confirmar cerrar sesión"}
        message={
          "Si desea cerrar sesión seleccione confirmar, de lo contrario puedes cancelar esta acción"
        }
        onCancel={() => setOpen(false)}
        onConfirm={handleLogoutCompleted}
      />
    </nav>
  );
};
