import React from "react";

export const ProgressRowItemDetail = ({ detail }) => {
  /*   console.log(detail); */
  return (
    <>
      <tr>
        <th scope="row" className="title-row">
          {detail.itemname}
        </th>
        <td>{detail.averageformatted || "-"}</td>
        <td>{detail.gradeformatted || "-"}</td>
        <td>
          {detail.rangeformatted
            ? detail.rangeformatted.split("&ndash;").join("-")
            : "-"}
        </td>
        <td>{detail.percentageformatted}</td>
        <td>{detail.feedback || "-"}</td>
      </tr>
    </>
  );
};
