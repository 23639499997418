import React, { useEffect, useState } from "react";
import "./detail.css";
import { useNavigate, useParams } from "react-router-dom";
// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CourseContentModuleRowComponent } from "../../components/ModuleContentRowComponent";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useMoodleV2 } from "../../contexts/moodle";
import { useTheme } from "../../contexts/ThemeContext";
import { useAuth } from "../../contexts/AuthContext";
import { getUserNru } from "../../services/tipddy/nru";
import { defaultSenceValidation } from "../../utils/handler";
import { SenceValidation } from "../../services/sence";
import { FormSence } from "../../services/sence/form";
import { CustomSpinner } from "../../components/Loader/custom.spinner";
import groupIcon from "../../assets/grupo-gris.png";
import { useConfig } from "../../contexts/config";

export const DetalleCurso = () => {
  const theme = useTheme();
  const params = useParams();
  const moodlev2 = useMoodleV2();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  const [group, setGroup] = useState(null);

  useEffect(() => {
    if (params.id === undefined) {
      navigate("/listcourses");
    } else {
      moodlev2
        .getCourseContentByID(params.id, true, window.location.search)
        .catch((err) => {
          console.log(err);
        });
    }

    return () => {
      moodlev2.setSelectedView(0);
    }; // eslint-disable-next-line
  }, []);

  /* Inicio verificacion SENCE */
  React.useEffect(() => {
    if (moodlev2.currentCourse) {
      console.log("VerifySence");
      VerifySence();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moodlev2.currentCourse]);

  async function fetchNRU() {
    // console.log({ userData: auth.user });
    let args = {
      courseid: moodlev2.currentCourse.id,
      userid: auth.user.id,
    };
    return Promise.resolve(await getUserNru(args));
  }

  function isSenceRequired(isSence, nru) {
    let required = false;
    let session = localStorage.getItem("sence_session");
    if (isSence && nru && !session) {
      required = true;
    }
    return required;
  }

  async function VerifySence() {
    let data = defaultSenceValidation(moodlev2.currentCourse);
    console.log({ defaultSenceValidation: data.isSence });
    if (data.isSence) {
      let nru = null;
      let rawNru = await fetchNRU();
      // if (auth.user) {
      //   let id = auth.user.id;
      //   if (id === 4) {
      //     rawNru = [{ rnu: id }];
      //   }
      // }
      console.log({ rawNru });
      if (rawNru && rawNru.length > 0) {
        nru = rawNru[0].rnu;
        localStorage.setItem("nru", nru);
      }
      let isRequired = isSenceRequired(data.isSence, nru);
      console.log({ isSenceRequired: isRequired });
      if (isRequired) {
        let validation = {
          idSence: data.idSence,
          auth,
          session: null,
          isLogin: true,
          nru,
        };
        localStorage.setItem("idSence", data.idSence);
        localStorage.setItem("current_course", params.id);
        return SenceValidation(validation);
      } else {
        return setLoading(false);
      }
    } else {
      return setLoading(false);
    }
  }

  /* Fin verificacion SENCE */

  useEffect(() => {
    moodlev2.getCourseUserList(params.id).then((res) => {
      setGroup(res);
    });
    //eslint-disable-next-line
  }, [params.id]);

  return (
    <>
      {loading && <CustomSpinner />}
      <FormSence />
      <DetailCourseWrapper>
        <CoreVisualContent
          moodlev2={moodlev2}
          token={auth.user.token}
          layout={theme.layout}
        />
        {window.screen.width > 1024 && (
          <CourseContentContainer layout={theme.layout}>
            <CourseTitle moodleV2={moodlev2} params={params} />
            <TabsRow />
            {group && <GroupRow group={group} />}
            <ContentPickerContainer>
              {moodlev2.currentModules &&
                moodlev2.currentModules.map((module, idx) => {
                  return (
                    <CourseContentModuleRowComponent
                      module={module}
                      key={module.id}
                      moodle={moodlev2}
                      idx={idx}
                    />
                  );
                })}
              {!moodlev2.currentModules && <SkeletonModules />}
            </ContentPickerContainer>
          </CourseContentContainer>
        )}
      </DetailCourseWrapper>
    </>
  );
};

const SkeletonModules = () => {
  return (
    <>
      {[1, 2, 3, 4, 5].map((k, i) => {
        return <SkeletonModule key={i} />;
      })}
    </>
  );
};

const SkeletonModule = () => {
  return (
    <div
      style={{ display: "flex", padding: "1.2rem 2rem", alignItems: "center" }}
    >
      <Skeleton
        baseColor="#adb5bd"
        style={{
          opacity: 0.1,
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          marginRight: "1rem",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.1,
            width: "250px",
            height: "12px",
            marginLeft: "5px",
            borderRadius: "10px",
          }}
        />
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.1,
            width: "220px",
            height: "12px",
            marginLeft: "5px",
            borderRadius: "10px",
          }}
        />
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.1,
            width: "200px",
            height: "12px",
            marginLeft: "5px",
            borderRadius: "10px",
          }}
        />
      </div>
    </div>
  );
};

const DetailCourseWrapper = ({ children }) => {
  return (
    <div className="detail-course-container">
      <div className="row mx-0" style={{ height: "92vh", maxHeight: "92vh" }}>
        {children}
      </div>
    </div>
  );
};

const CoreVisualContent = ({ moodlev2, token, layout }) => {
  let embed = moodlev2.pageEmbed;
  let resourceContent = moodlev2.labelContent;
  const auth = useAuth();
  const config = useConfig();

  const frame = React.useRef(null);

  React.useEffect(() => {
    let refreshTrigger = localStorage.getItem("refresh");
    let newaccess = Number(localStorage.getItem("newaccess"));
    if (!refreshTrigger && newaccess) {
      let last = newaccess;
      let new_ = auth.user.id;
      let refresh = last === new_ ? false : true;
      if (refresh) {
        frame.current.addEventListener("load", () => {
          let url = window.location.pathname;
          localStorage.setItem("refresh", true);
          localStorage.removeItem("newaccess");
          window.location.replace(`${url}?watch=${new Date().getTime()}`);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frame]);

  /* Inicio codigo que recibe postMessage para poder cotejar si el iframe se encuentra en listado curso
   * el cual no debería estar visible
   */
  function transformURL(url) {
    // Escapar los caracteres especiales en la URL
    let escapedURL = url.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
    // Construir la expresión regular con la parte variable
    let regexString = escapedURL + "course\\/view\\.php\\?id=\\d+";
    // Crear la expresión regular
    let regex = new RegExp(regexString);
    return regex;
  }

  React.useEffect(() => {
    window.addEventListener("message", function (event) {
      let url = config.config.baseURL;
      let urlPattern = transformURL(url);
      // Verifica que el mensaje provenga del dominio esperado
      if (urlPattern.test(event.data.href)) {
        // redireccionar iframe a pagina 404
        frame.current.src = `${url}course/404.php`;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embed]);
  /* Fin codigo recibir postMessaage */
  /* Inicio Image Embed code */

  const handleImgToken = (html, token) => {
    // Crear un elemento div para cargar el HTML como texto
    let src_token_with_param = `?token=${token}`;
    let src_token_without_param = `&token=${token}`;
    let div = document.createElement("div");
    div.innerHTML = html;

    // Obtener todas las etiquetas de imagen
    let imagenes = div.getElementsByTagName("img");

    // Recorrer todas las etiquetas de imagen y agregar el token al final del atributo src
    for (let i = 0; i < imagenes.length; i++) {
      let src = imagenes[i].getAttribute("src");
      if (src.includes("?")) {
        imagenes[i].setAttribute("src", src + src_token_without_param);
      } else {
        imagenes[i].setAttribute("src", src + src_token_with_param);
      }
    }

    // Devolver el HTML modificado
    return div.innerHTML;
  };

  if (embed && embed.includes("<img")) {
    let new_embed = handleImgToken(embed, token);

    return (
      <div
        className={layout ? "col-xl-9 summary-html" : "col-xl-12 summary-html"}
        style={{
          position: "relative",
          height: "100%",
          borderBottom: "2px solid #ccc",
          transition: "0.5s",
        }}
      >
        <div
          style={{
            maxHeight: "100%",
            width: "100%",
            overflow: "scroll",
          }}
          dangerouslySetInnerHTML={{ __html: new_embed }}
        ></div>
        {/* <NavigationButtons layout={layout} /> */}
      </div>
    );
  }

  /* Fin Image Embed */
  if (embed && !embed.startsWith("http")) {
    return (
      <div
        className={layout ? "col-xl-9 summary-html" : "col-xl-12 summary-html"}
        style={{
          position: "relative",
          height: "100%",
          borderBottom: "2px solid #ccc",
          transition: "0.5s",
        }}
      >
        <div
          style={{
            maxHeight: "100%",
            width: "100%",
            overflow: "scroll",
          }}
          dangerouslySetInnerHTML={{ __html: embed }}
        ></div>
        {/* <NavigationButtons layout={layout} /> */}
      </div>
    );
  }

  /* Inicio Label content */
  if (resourceContent && !embed) {
    return (
      <div
        className={layout ? "col-xl-9 summary-html" : "col-xl-12 summary-html"}
        style={{
          position: "relative",
          height: "100%",
          borderBottom: "2px solid #ccc",
          transition: "0.5s",
        }}
      >
        <div
          style={{
            maxHeight: "100%",
            width: "100%",
            overflow: "scroll",
          }}
          dangerouslySetInnerHTML={{ __html: resourceContent }}
        ></div>
        {/* <NavigationButtons layout={layout} /> */}
      </div>
    );
  }
  /* Fin Label content */

  return (
    <div
      className={layout ? "col-md-9 summary-html" : "col-md-12 summary-html"}
      style={{
        position: "relative",
        minHeight: "100%",
        padding: 0,
        transition: "0.5s",
      }}
    >
      <iframe
        ref={frame}
        src={embed}
        id="video-player"
        frameBorder="0"
        width="100%"
        allow="autoplay"
        height={"100%"}
        title="video"
        allowFullScreen={true}
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
      />
      {/* <NavigationButtons /> */}
    </div>
  );
};

const CourseContentContainer = ({ children, layout }) => {
  return (
    <div
      className={layout ? "col-md-3" : "col-md-0"}
      style={{
        height: "100%",
        maxHeight: "100%",
        padding: "0 5px",
        transition: "0.5s",
        borderLeft: "1px solid grey",
        overflowY: "scroll",
      }}
    >
      {children}
    </div>
  );
};

/* const DiagnosticEvaluationButton = () => {
  return (
    <button
      type="button"
      className="btn btn-primary btn-lg btn-block btn-evaluacion"
    >
      Realizar Evaluación Diagnóstica
    </button>
  ); 
};
*/

const CourseTitle = ({ moodleV2, params }) => {
  const [title, setTitle] = useState("titulo");
  const { user } = useAuth();
  useEffect(() => {
    if (moodleV2 && moodleV2.courses) {
      let course_title = moodleV2.courses.filter(
        (e) => e?.id === Number(params.id)
      );
      setTitle(course_title[0].displayname);
    }
    if (moodleV2 && !moodleV2.courses && user.id && user.token) {
      moodleV2.getCourses();
    }
    //eslint-disable-next-line
  }, [moodleV2?.courses, user]);

  return <div className="row mx-0 course-title-row">{title}</div>;
};

const TabsRow = () => {
  const moodle = useMoodleV2();
  const theme = useTheme();

  React.useEffect(() => {
    console.log("moodle.humhub: ", moodle.humhub);
  }, [moodle.humhub]);

  return (
    <div className="row mx-0 tabs-row">
      <button
        type="button"
        className={`${
          moodle.humhub
            ? "col-md-6 btn btn-primary btn-tab-course"
            : "col-md-4 btn btn-primary btn-tab-course-2"
        }`}
        onClick={() => moodle.contentSelected()}
        style={{
          fontSize: 11,
          backgroundColor:
            moodle.selectedView === 0
              ? theme.mode === "light"
                ? "#69b7cb"
                : "#FFCC00"
              : "#15171f",
          color: moodle.selectedView === 0 ? "#fff" : "#b2b2b2",
        }}
      >
        Contenido
      </button>
      <button
        type="button"
        className={`${
          moodle.humhub
            ? "col-md-6 btn btn-primary btn-tab-course"
            : "col-md-4 btn btn-primary btn-tab-course-2"
        }`}
        onClick={() => moodle.programSelected()}
        style={{
          fontSize: 11,
          backgroundColor:
            moodle.selectedView === 1
              ? theme.mode === "light"
                ? "#69b7cb"
                : "#FFCC00"
              : "#15171f",
          color: moodle.selectedView === 1 ? "#fff" : "#b2b2b2",
        }}
      >
        Programa
      </button>
      {moodle.humhub && (
        <button
          type="button"
          className="col-md-6 btn btn-primary btn-tab-course"
          onClick={() => moodle.humhubSelected()}
          style={{
            backgroundColor: "#15171f",
            color: moodle.selectedView === 1 ? "#fff" : "#b2b2b2",
          }}
        >
          Comunidad
        </button>
      )}

      <button
        type="button"
        className={`${
          moodle.humhub
            ? "col-md-6 btn btn-primary btn-tab-course"
            : "col-md-4 btn btn-primary btn-tab-course-2"
        }`}
        onClick={() => moodle.onlineSelected()}
        style={{
          fontSize: 11,
          backgroundColor:
            moodle.selectedView === 2
              ? theme.mode === "light"
                ? "#69b7cb"
                : "#FFCC00"
              : "#15171f",
          color: moodle.selectedView === 2 ? "#fff" : "#b2b2b2",
        }}
      >
        Clases en línea
      </button>
    </div>
  );
};

// const TabsRowV1 = () => {
//   const moodle = useMoodleV2();

//   return (
//     <div className="row mx-0 tabs-row">
//       <button
//         type="button"
//         className="col-md-4 btn btn-primary btn-tab-course"
//         onClick={() => moodle.contentSelected()}
//         style={{
//           fontSize: 11,
//           backgroundColor: moodle.selectedView === 0 ? "#69b7cb" : "#15171f",
//           color: moodle.selectedView === 0 ? "#fff" : "#b2b2b2",
//         }}
//       >
//         Contenido
//       </button>
//       <button
//         type="button"
//         className="col-md-4 btn btn-primary btn-tab-course"
//         onClick={() => moodle.programSelected()}
//         style={{
//           fontSize: 11,
//           backgroundColor: moodle.selectedView === 1 ? "#69b7cb" : "#15171f",
//           color: moodle.selectedView === 1 ? "#fff" : "#b2b2b2",
//         }}
//       >
//         Programa
//       </button>
//       <button
//         type="button"
//         className="col-md-4 btn btn-primary btn-tab-course"
//         onClick={() => moodle.onlineSelected()}
//         style={{
//           backgroundColor: moodle.selectedView === 2 ? "#69b7cb" : "#15171f",
//           color: moodle.selectedView === 2 ? "#fff" : "#b2b2b2",
//           fontSize: 11,
//         }}
//       >
//         Clases en línea
//       </button>
//     </div>
//   );
// };

const GroupRow = ({ group }) => {
  return (
    <div className="row mx-0 group-row">
      <div className="group-text">
        <img className="group-icon" src={groupIcon} alt="group icon" />
        Grupo: {group}
      </div>
    </div>
  );
};

const ContentPickerContainer = ({ children }) => {
  return (
    <div
      className="content-container"
      style={{
        borderRadius: "20px",
        overflowY: "hidden",
        height: "auto",
        paddingBottom: 150,
        marginTop: "1rem",
      }}
    >
      {children}
    </div>
  );
};

// const NavigationButtons = () => {
//   const theme = useTheme();
//   const moodlev2 = useMoodleV2();

//   const styles = {};

//   styles.container = {
//     position: "absolute",
//     bottom: 42,
//     right: 10,
//     zIndex: 1000,
//     width: "fit-content",
//     display: "flex",
//     backgroundColor: "transparent",
//     padding: "0rem",
//   };

//   styles.innerContainer = {
//     display: "flex",
//     alignItems: "center",
//     border: "1px solid #b2b2b2",
//     borderRadius: 9,
//   };

//   styles.prevButton = {
//     borderTopLeftRadius: 8,
//     borderBottomLeftRadius: 8,
//     height: 42,
//     width: 50,
//     backgroundColor: theme.mode === "dark" ? "#FAFAFA" : "#888866",
//     cursor: "pointer",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     padding: "1.2rem",
//   };

//   styles.nextButton = {
//     backgroundColor:
//       theme.mode === "dark" ? "#999999" : theme.palette.secondary,
//     color: theme.mode === "dark" ? "#fff" : "#fff",
//     height: 42,
//     width: "100%",
//     padding: "1.2rem",
//     borderTopRightRadius: 8,
//     borderBottomRightRadius: 8,
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     cursor:
//       moodlev2.nextResourceName === "Fin del curso" ? "default" : "pointer",
//   };

//   styles.arrowRightIcon = { transform: "translateY(1px)" };

//   styles.arrowLeftIcon = { color: theme.mode === "dark" ? "#000" : "#fff" };

//   const handleNext = async () => {
//     if (moodlev2.nextResourceName === "Fin del curso") {
//       return;
//     } else {
//       await moodlev2.navigateNext();
//     }
//   };

//   const handlePrev = async () => {
//     await moodlev2.navigatePrev();
//   };

//   return (
//     <div style={styles.container}>
//       <div style={styles.innerContainer}>
//         <div style={styles.prevButton} onClick={handlePrev}>
//           <KeyboardArrowLeftIcon sx={styles.arrowLeftIcon} />
//         </div>
//         <div style={styles.nextButton} onClick={handleNext}>
//           <p className="m-0">{moodlev2.nextResourceName}</p>
//           {moodlev2.nextResourceName === "Fin del curso" ? (
//             <></>
//           ) : (
//             <KeyboardArrowRightIcon sx={styles.arrowRightIcon} />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };
