import React, { useRef } from "react";
import flecha_s from "./assets/flecha-s.png";
import "./index.css";
import { removeHTML } from "../../utils";
import { useNavigate } from "react-router-dom";
import { DEFAULT_HEADER_DATA } from "../../utils/state";
/* import { useTheme } from "../../contexts/ThemeContext"; */
import { HeaderSkeleton } from "./skeleton";
import { useConfig } from "../../contexts/config";

/* to work on */
export const HeaderCourses = ({ moodle }) => {
  const header = useRef(null);
  const { config } = useConfig();
  const [state, setState] = React.useState(DEFAULT_HEADER_DATA);
  const navigation = useNavigate();

  React.useEffect(() => {
    console.log({ moodle });
    if (moodle.courses && moodle.courses.length > 0) {
      setTimeout(() => {
        setState({
          course: moodle.courses[0],
          text: removeHTML(moodle.courses[0].summary),
        });
      }, 700);
    }
    // eslint-disable-next-line
  }, [moodle]);

  React.useEffect(() => {
    if (config && config.config.ads.length > 0) {
      header.current.style.backgroundImage = `url(${config.config.ads[0].banner})`;
    }
    // eslint-disable-next-line
  }, []);

  const handleNavigation = () =>
    navigation(`/detailcourse/v2/${state.course.id}`);

  return (
    <div style={{ paddingTop: 80 }}>
      <header style={{ padding: "20px 0", minHeight: 450 }} ref={header}>
        <div className="container mar">
          <div className="col-md-12">
            <div className="row">
              {!state.course ? (
                <HeaderSkeleton />
              ) : (
                <>
                  <div className="col-md-8">
                    <h1 className="tit-principal" style={{ padding: 0 }}>
                      {state.course.fullname}
                    </h1>
                    <p className="text-generico"></p>
                    <p className="text-generico-res">{state.text}</p>
                    <p className="text-generico-res">
                      <button
                        type="button"
                        className="btn btn-primary bot-generico"
                        onClick={handleNavigation}
                      >
                        <img src={flecha_s} alt="..." className="" />
                        Entrar al curso
                      </button>
                    </p>
                  </div>
                </>
              )}
              <div className="col-md-4 pos-img"></div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
