import React from "react";
import { routes } from "./routes";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "./protected.route";
import { useAuth } from "../contexts/AuthContext";
import { Layout } from "../components/Layout";

const CustomRoutes = () => {
  const auth = useAuth();

  React.useEffect(() => {
    async function VerifyAuth() {
      return Promise.resolve(await auth.Authenticate());
    }
    if (!auth.user) {
      VerifyAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, idx) => {
          const Element = route.requireAuth ? (
            <Layout>
              <ProtectedRoute>
                {" "}
                <route.element />{" "}
              </ProtectedRoute>
            </Layout>
          ) : (
            <route.element />
          );

          return <Route key={idx} path={route.path} element={Element} />;
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default CustomRoutes;
