import "./App.css";
import Routes from "./routes";
import { AuthProvider } from "./contexts/AuthContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import { MoodleProviderV2 } from "./contexts/moodle";
import { useEffect } from "react";
import { NativeSpinner } from "./components/Loader/spinner";
import { useConfig } from "./contexts/config";

function App() {
  const config = useConfig();

  useEffect(() => {
    if (!config.config) {
      config
        .getConfig()
        .then(console.log)
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, []);

  if (!config.config) {
    return <NativeSpinner />;
  }

  if (config.config) {
    return (
      <ThemeProvider>
        <AuthProvider>
          <MoodleProviderV2>
            <Routes />
          </MoodleProviderV2>
        </AuthProvider>
      </ThemeProvider>
    );
  }
}

export default App;
