import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { defaultHandleChange } from "../../utils/handler";
import { DEFAULT_CREDENTIALS } from "../../utils/state";
import { LoginForm } from "../../components/LoginForm";
import { Snackbar } from "../../components/Snackbar";
import {
  DEFAULT_SNACKBAR,
  ERROR_CREDENTIALS,
  ERROR_EMPTY_CREDENTIALS,
  SUCCESS_CREDENTIALS,
} from "../../utils/snackbar";
import { useConfig } from "../../contexts/config";
import { useTheme } from "../../contexts/ThemeContext";
import "./styles.css";
import { useMoodleV2 } from "../../contexts/moodle";
import { readableColor } from "polished";

const Login = () => {
  const { config } = useConfig();
  const theme = useTheme();
  const auth = useAuth();
  const navigate = useNavigate();
  const [credentials, setCredentials] = React.useState(DEFAULT_CREDENTIALS);
  const [loading, setLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(DEFAULT_SNACKBAR);
  const [contrastColorSecondary, setcontrastColorSecondary] =
    React.useState("green");
  const [contrastColorPrimary, setcontrastColorPrimary] = React.useState("red");
  const moodle = useMoodleV2();

  React.useEffect(() => {
    let bg_img = config.config.theme.background_login;
    document.body.classList.add("imagen2");
    // if (localStorage.getItem("token")) navigate("/listcourses");

    if (bg_img) {
      document.body.style.backgroundImage = `url(${bg_img})`;
    }

    return () => {
      document.body.style.backgroundImage = ``;
      document.body.classList.remove("imagen2");
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (theme && theme.palette.secondary) {
      setcontrastColorSecondary(
        readableColor(theme.palette.secondary, "#000", "#fff", false)
      );
    }
    if (theme && theme.palette.primary) {
      setcontrastColorPrimary(
        readableColor(theme.palette.primary, "#000", "#fff", false)
      );
    }
    // eslint-disable-next-line
  }, [theme]);

  React.useEffect(() => {
    auth.logout(moodle);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isEmpty = Object.values(credentials).some((value) => value === "");
    if (isEmpty) {
      setSnackbar(ERROR_EMPTY_CREDENTIALS);
      setTimeout(() => {
        setSnackbar(DEFAULT_SNACKBAR);
      }, 2000);
      return;
    }
    let clean_username = credentials.username.replace(/\s/g, "").toLowerCase();
    try {
      setLoading(true);
      await auth.Login({ ...credentials, username: clean_username });
      setSnackbar(SUCCESS_CREDENTIALS);
      setTimeout(() => {
        setLoading(false);
        setSnackbar(DEFAULT_SNACKBAR);
        setCredentials(DEFAULT_CREDENTIALS);
        document.body.classList.remove("imagen3");
        navigate("/listcourses");
      }, 1000);
    } catch (error) {
      console.log(error.message);
      setSnackbar(ERROR_CREDENTIALS);
      setLoading(false);
      setTimeout(() => {
        setSnackbar(DEFAULT_SNACKBAR);
      }, 2000);
    }
  };

  const handleChange = (e) =>
    defaultHandleChange(e, credentials, setCredentials);

  return (
    <>
      <div className="container esp-log">
        <img
          src="https://par.tuclase.cl/loginalt/images/logo2.png"
          alt="logo"
          className="posi-logo posi-log-b"
          style={{ visibility: "hidden" }}
        />
      </div>
      <div className="container">
        <div className="row mx-4">
          <div className="col-md-1"></div>
          <div
            className="col-md-4  trans2 sac-new"
            style={{ background: theme.palette.secondary }}
          >
            <div
              className="fon-logo-new2 sec-desktop"
              style={{ background: theme.palette.primary }}
            >
              <p className="container-logo-login">
                <img
                  className="logo-login"
                  src={config.config.theme.logo}
                  alt="..."
                />
              </p>
              <p
                className="iniciar-new"
                style={{
                  color: contrastColorPrimary,
                }}
              >
                Iniciar Sesión
              </p>
            </div>
            <div
              className="fon-logo-new2 sec-movil"
              style={{ background: theme.palette.primary }}
            >
              <div className="col-md-12">
                <div className="row">
                  <div className="col-xs-8 col-md-8">
                    <p className="">
                      <img
                        alt="img"
                        src={config.config.theme.logo}
                        className="img-responsive"
                      />
                    </p>
                  </div>
                  <div className="col-xs-4 col-md-4"></div>
                </div>
                <p
                  className="iniciar-new"
                  style={{
                    color: readableColor(
                      theme.palette.primary,
                      "#000",
                      "#fff",
                      true
                    ),
                  }}
                >
                  Iniciar Sesión
                </p>
              </div>
            </div>
            <LoginForm
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              credentials={credentials}
              loading={loading}
              contrastColorSecondary={contrastColorSecondary}
              contrastColorPrimary={contrastColorPrimary}
              theme={theme}
            />
          </div>
          <div className="col-md-7 text-center salir">
            <div className="row">
              <div className="col-md-8"></div>
              <div className="col-md-4"></div>
            </div>
            <h1 className="llamado">
              Experiencias de <br />
              aprendizaje
            </h1>
            <p className="llamado-d">a la medida, actualizadas y efectivas</p>
          </div>
        </div>
      </div>
      <Snackbar
        severity={snackbar.severity}
        visible={snackbar.visible}
        message={snackbar.message}
      />
    </>
  );
};

export default Login;
