import React from "react";
import { Link } from "react-router-dom";

export const LoginForm = ({
  handleChange,
  handleSubmit,
  loading,
  credentials,
  contrastColorSecondary,
  contrastColorPrimary,
  theme,
}) => {
  return (
    <>
      <style>
        {`
        .box-ingreso2::placeholder{
        color: ` +
          contrastColorSecondary +
          `;}
        .box-ingreso2:focus{
          background-color: ` +
          theme.palette.secondary +
          `;
          border: 1px solid` +
          contrastColorSecondary +
          `;}
          `}
      </style>
      <form
        onSubmit={handleSubmit}
        action="https://par.tuclase.cl/login/index.php"
        method="post"
      >
        <div className="col-md-12">
          <input
            onChange={handleChange}
            type="text"
            name="username"
            className="form-control box-ingreso2"
            placeholder="Usuario"
            style={{
              borderBottom: `1px solid ${contrastColorSecondary}`,
              color: contrastColorSecondary,
            }}
            value={credentials.username}
          />
        </div>
        <div className="col-md-12">
          <input
            onChange={handleChange}
            type="password"
            name="password"
            className="form-control box-ingreso2"
            placeholder="Contraseña"
            style={{
              borderBottom: `1px solid ${contrastColorSecondary}`,
              color: contrastColorSecondary,
            }}
            value={credentials.password}
          />
        </div>
        <div className="col-md-12">
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block bot-text"
            style={{
              backgroundColor: theme.palette.primary,
              color: contrastColorPrimary,
            }}
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-md mx-3"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Ingresa"
            )}
          </button>
        </div>
        <div className="col-md-12">
          <p className="cuenta">
            <Link
              to="/change-password"
              style={{ color: contrastColorSecondary }}
            >
              ¿Olvidaste tu contraseña?
            </Link>
          </p>
        </div>
      </form>
    </>
  );
};
