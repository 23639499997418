import React from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
/* import { removeHTML } from '../../utils' */
import flecha_s from "../HeaderCourses/assets/flecha-s.png";
import { useTheme } from "../../contexts/ThemeContext";
/* import { ProgressBar } from '../ProgressBar' */

export const CourseCard = ({ item = null, moodle, img }) => {
  const [isExpired, setExpired] = React.useState(false);
  const theme = useTheme();
  /*   const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }; */
  const navigation = useNavigate();
  /*   const enddate = new Date(item.startdate).toDateString() */

  const backgroundref = React.useRef(null);

  React.useEffect(() => {
    if (item.overviewfiles.length > 0) {
      let imgurl = moodle.getCourseImg(item);
      backgroundref.current.style.backgroundImage = `url(${imgurl})`;
      // backgroundref.current.src = `${imgurl}`;
    } else {
      backgroundref.current.style.backgroundImage = `url(${img})`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigation = () => navigation(`/detailcourse/v2/${item.id}`);

  const compareYear = (t) => {
    let course_date = new Date(t * 1000);
    let course_year = course_date.getFullYear();

    let current_date = new Date();
    let current_year = current_date.getFullYear();

    if (course_year === current_year) {
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    // console.log({ theme });
    if (window.location.href.includes("casg")) {
      setExpired(compareYear(item.startdate));
    }
    //eslint-disable-next-line
  }, []);

  if (isExpired) return;

  return (
    <div className="col-md-4" style={{ margin: "4rem 0" }}>
      <div
        className="box-text"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: theme.mode === "dark" ? "#294661" : "#ece8e8",
        }}
      >
        {/* <img ref={backgroundref} src={img} alt="..." className="img-fluid" /> */}
        <div ref={backgroundref} alt="..." className="img-fluid card-img"></div>
        <h2 className="tit-box" style={{ ...theme.styles.title_text }}>
          {item.fullname}
        </h2>
        <div style={{ padding: "5px 15px 10px 15px" }}>
          <progress id="file" max="100" value={item?.progress?.toFixed(0) || 0}>
            {item?.progress?.toFixed(0) || 0}%
          </progress>
          <div>
            <p
              className="tex-sec-car"
              style={{ padding: 0, ...theme.styles.title_text }}
            >
              {item?.progress?.toFixed(0) || 0}% Completado
            </p>
          </div>
        </div>

        <p className="text-box">
          <button
            onClick={handleNavigation}
            type="button"
            className="btn btn-primary bot-generico"
          >
            <img src={flecha_s} alt="..." className="" />
            Entrar al curso
          </button>
        </p>
      </div>
    </div>
  );
};
