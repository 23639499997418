import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useMoodleV2 } from "../../contexts/moodle";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

export const CalendarApp = ({
  isCalendarOpen,
  handleCalendar,
  setEventsCount,
}) => {
  const auth = useAuth();
  const moodle = useMoodleV2();
  const navigate = useNavigate();
  const [events, setEvents] = useState(null);

  React.useEffect(() => {
    moodle &&
      auth.user &&
      moodle
        .fetchEvents()
        .then((res) => {
          if (res.events) {
            let finalevents = res.events.map((e) => {
              return {
                ...e,
                formatdate: normalizeDate(e.timesort),
                isExpired: checkExpiration(e.timesort),
              };
            });
            let availableEvents = finalevents.filter((e) => !e.isExpired);
            setEventsCount(availableEvents.length);
            setEvents(availableEvents);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    // eslint-disable-next-line
  }, [auth.user]);

  const MONTHS = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  function normalizeDate(time) {
    let d = new Date(time * 1000);
    let day = d.getDate();
    let month = d.getMonth();

    return `${day} ${MONTHS[month]}`;
  }

  function checkExpiration(date) {
    let currentDate = new Date();
    let eventDate = new Date(date * 1000);
    if (eventDate.getTime() < currentDate.getTime()) {
      return true;
    } else {
      return false;
    }
  }

  let styles = {};

  styles.calendarcontainer = {
    transition: ".5s",
    position: "fixed",
    width: "320px",
    height: "100vh",
    backgroundColor: "#d6eef5",
    right: isCalendarOpen ? "0" : "-350px",
    top: "0px",
    boxShadow:
      "rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px",
    zIndex: 1002,
  };

  styles.title = {
    fontSize: "14px",
    textTransform: "uppercase",
    color: "#fff",
    fontWeight: "500",
  };

  styles.tabContainer = {
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid #ccc",
    padding: "1rem 0",
    backgroundColor: "rgb(0, 121, 172)",
    margin: 0,
  };

  const handleEventNavigation = (event) => {
    let instance = event.instance;
    let courseid = event.course?.id;

    if (courseid) {
      navigate(`/detailcourse/v2/${courseid}?instance=${instance}`);
    }
  };

  return (
    <div style={styles.calendarcontainer}>
      <div style={styles.tabContainer}>
        <p style={styles.title}>Calendario</p>
        <IconButton
          onClick={handleCalendar}
          sx={{
            position: "absolute",
            top: 2,
            right: 20,
          }}
        >
          <CloseIcon
            sx={{
              fontSize: 20,
              color: "#fff",
            }}
          />
        </IconButton>
      </div>
      {events &&
        events.length > 0 &&
        events.map((e, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid #b2b2b2",
                margin: 0,
                justifyContent: "space-between",
                padding: "1.1rem .7rem",
                height: 95,
                backgroundColor: "#cfd8dc",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 15,
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    transform: "translateY(15px)",
                    marginLeft: 5,
                    marginRight: 10,
                    fontSize: 12,
                    color: "#0079ac",
                    fontWeight: "7  00",
                    width: "15%",
                  }}
                >
                  {e.formatdate}
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#605454",
                    marginRight: 20,
                    fontWeight: 500,
                    width: "100%",
                  }}
                >
                  {e.name}
                  <br />
                  {Date(e.timeusermidnight)}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    fontSize: "10px",
                    color: "#0079ac",
                    marginLeft: 55,
                    fontWeight: 500,
                  }}
                >
                  {e.normalisedeventtypetext}
                </p>
                <p
                  onClick={() => handleEventNavigation(e)}
                  style={{
                    fontSize: "10px",
                    color: "#0079ac",
                    marginRight: 20,
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  Ir al evento
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
};
