import FolderIcon from "@mui/icons-material/Folder";
import ForumIcon from "@mui/icons-material/Forum";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import QuizIcon from "@mui/icons-material/Quiz";
import FeedbackIcon from "@mui/icons-material/Feedback";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ChatIcon from "@mui/icons-material/Chat";
import PreviewIcon from "@mui/icons-material/Preview";
import ImageIcon from "@mui/icons-material/Image";
import LinkIcon from "@mui/icons-material/Link";
import StorageIcon from "@mui/icons-material/Storage";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";

let styles = {
  color: "#fff",
  marginRight: "10px",
  fontSize: 20,
};

export const ICONS = {
  forum: <ForumIcon sx={styles} />,
  pdf: <PictureAsPdfIcon sx={styles} />,
  choice: <QuizIcon sx={styles} />,
  feedback: <FeedbackIcon sx={styles} />,
  scorm: <BackupTableIcon sx={styles} />,
  assign: <AssignmentIcon sx={styles} />,
  folder: <FolderIcon sx={styles} />,
  chat: <ChatIcon sx={styles} />,
  page: <PreviewIcon sx={styles} />,
  label: <PreviewIcon sx={styles} />,
  png: <ImageIcon sx={styles} />,
  quiz: <AssignmentIcon sx={styles} />,
  url: <LinkIcon sx={styles} />,
  data: <StorageIcon sx={styles} />,
  customcert: <WorkspacePremiumIcon sx={styles} />,
  vimeo: <SmartDisplayIcon sx={styles} />,
};
