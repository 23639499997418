import axios from "axios";

export const getEventsByCourses = async ({ token, baseURL }) => {
  console.log({ baseURL });
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_calendar_get_action_events_by_course&courseid=${72}`
    );
    // console.log("getEventsByCourses", data);
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getEventsByTimesort = async ({ token, userid, baseURL }) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_calendar_get_action_events_by_timesort&userid=${userid}`
    );
    // console.log("getEventsByTimesort", data);
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
