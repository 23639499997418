import { useEffect, useState } from "react";
import { useMoodleV2 } from "../../../contexts/moodle";
import { Suspense } from "./suspense";

export const Chats = ({ selected, chats, setChats, openChat, auth }) => {
  let isSelectedView = selected === "chats";
  let moodle = useMoodleV2();

  useEffect(() => {}, []);

  useEffect(() => {
    if (isSelectedView && !chats) {
      setTimeout(() => {
        moodle
          .getConversations()
          .then((data) => {
            setChats(data.conversations);
          })
          .catch((err) => console.log(err));
      }, 3000);
    }
    // eslint-disable-next-line
  }, [isSelectedView]);

  if (isSelectedView && chats) {
    return chats.map((chat) => (
      <ChatItem chat={chat} key={chat.id} openChat={openChat} auth={auth} />
    ));
  }

  if (isSelectedView && !chats) {
    return <Suspense />;
  }

  return null;
};

const ChatItem = ({ chat = null, openChat, auth }) => {
  const [hover, setHover] = useState(false);
  if (!chat) {
    return null;
  }

  let profile =
    chat.members.filter((chat) => chat.id !== auth.user.id)[0] ||
    chat.members[0];

  let styles = {};

  styles.container = {
    padding: "2rem",
    borderBottom: "1px solid #ccc",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: hover ? "#cfd8dc" : "unset",
  };

  styles.img = {
    borderRadius: "50%",
    marginRight: "2rem",
  };

  return (
    <>
      <div
        style={styles.container}
        onClick={() => openChat(chat)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <img alt="..." src={profile?.profileimageurlsmall} style={styles.img} />
        <div style={{ marginRight: "auto" }}>
          <p>{profile?.fullname}</p>
        </div>
        {!chat.isread && (
          <div
            style={{
              backgroundColor: "#b2b2b2",
              display: "flex",
              justifyContent: "center",
              height: 20,
              width: 20,
              alignItems: "center",
              borderRadius: "50%",
            }}
          >
            <p style={{ color: "#cfd8dc" }}>{chat.unreadcount}</p>
          </div>
        )}
      </div>
    </>
  );
};
