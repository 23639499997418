export function removeHTML(str) {
  if (str === "") return null;
  let tmp = document.createElement("div");
  tmp.innerHTML = str;
  return tmp.textContent;
}

/* to work on */
export function extractSRC(str) {
  let el = document.createElement("div");
  el.innerHTML = str;
  let src = el.children[0].children[0].src;
  return src;
}

export const query = (selector) => document.querySelector(selector) || null;

export const mount = (selector) => document.createElement(selector);

export const fetcher = async (url, options) => {
  let res = await fetch(url, options)
  if(!res.ok){
    throw new Error(res.status)
  }
  return await res.json()
}
