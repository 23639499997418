import { useTheme } from "../../contexts/ThemeContext";

export const ResumeCourses = ({ children }) => {
  const theme = useTheme();
  return (
    <>
      <div className="wrapper fon-sec-car" style={theme.styles.bg_course_group}>
        <div className="container">
          <div className="col-md-12">
            <div className="row d-flex justify-content-center">
              <div className="row">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
