// import { layout } from "platform";
import React, { useState } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { CalendarApp } from "../CalendarApp";
import { MessagesApp } from "../MessagesApp";
import { Navbar } from "../Navbar";
import { Sidebar } from "../Sidebar";
import { useMoodleV2 } from "../../contexts/moodle";
import { useAuth } from "../../contexts/AuthContext";

export const Layout = ({ children }) => {
  const [isCalendarOpen, setCalendar] = useState(false);
  const theme = useTheme();
  const [eventsCount, setEventsCount] = useState(null);
  const [sidebar, toggleSidebar] = React.useState(false);
  const [noticount, setNotiCount] = React.useState(null);
  const [notifications, setNotifications] = React.useState(null);
  const [opennotif, setOpenNotif] = React.useState(false);
  const moodle = useMoodleV2();
  const auth = useAuth();
  const [sidebarMsg, togglesidebarMsg] = React.useState(false);
  const [badge, setBadge] = React.useState(null);

  const handleMessagesApp = () => {
    let { style } = document.querySelector(".sidebar-container-messages");
    opennotif && setOpenNotif(!opennotif);
    isCalendarOpen && setCalendar(!isCalendarOpen);
    if (!sidebarMsg) {
      style.right = "0";
      return togglesidebarMsg(!sidebarMsg);
    }

    style.right = "-350px";
    togglesidebarMsg(!sidebarMsg);
  };

  const handleCalendar = () => {
    setCalendar(!isCalendarOpen);
    opennotif && setOpenNotif(!opennotif);
    sidebarMsg && handleMessagesApp();
  };

  const openNotifications = () => {
    setOpenNotif(!opennotif);
    isCalendarOpen && setCalendar(!isCalendarOpen);
    sidebarMsg && handleMessagesApp();
  };

  const handleDarkMode = () => {
    theme.actions.handleDarkMode();
  };

  React.useEffect(() => {
    moodle &&
      auth.user &&
      moodle
        .fetchNotifications()
        .then((res) => {
          setNotifications(res.notifications);
          setNotiCount(res.unreadcount);
        })
        .catch((err) => {
          console.log(err);
        });

    // eslint-disable-next-line
  }, [auth.user]);

  return (
    <>
      <Navbar
        handleCalendar={handleCalendar}
        layout={theme.layout}
        handleContentLayout={theme.handleContentLayout}
        eventsCount={eventsCount}
        sidebar={sidebar}
        toggleSidebar={toggleSidebar}
        noticount={noticount}
        setNotiCount={setNotiCount}
        notifications={notifications}
        setNotifications={setNotifications}
        openNotifications={openNotifications}
        opennotif={opennotif}
        handleDarkMode={handleDarkMode}
      />
      <Sidebar
        sidebar={sidebar}
        toggleSidebar={toggleSidebar}
        handleCalendar={handleCalendar}
        eventsCount={eventsCount}
        noticount={noticount}
        setNotiCount={setNotiCount}
        notifications={notifications}
        setNotifications={setNotifications}
        setOpenNotif={setOpenNotif}
        opennotif={opennotif}
        handleDarkMode={handleDarkMode}
        handleMessagesApp={handleMessagesApp}
        badge={badge}
        setBadge={setBadge}
      />
      <div style={{ height: "100vh" }}>{children}</div>
      <MessagesApp
        handleMessagesApp={handleMessagesApp}
        badge={badge}
        setBadge={setBadge}
      />
      <CalendarApp
        isCalendarOpen={isCalendarOpen}
        handleCalendar={handleCalendar}
        setEventsCount={setEventsCount}
      />
    </>
  );
};
