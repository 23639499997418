import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const HeaderSkeleton = () => {
  return (
    <>
      <Skeleton
        baseColor="#cccccc"
        style={{
          opacity: 0.5,
          width: "50%",
          height: "3.5rem",
          marginTop: "2rem",
        }}
      />
      <Skeleton
        baseColor="#cccccc"
        style={{
          opacity: 0.5,
          width: "40%",
          height: "3.5rem",
          marginTop: ".9rem",
        }}
      />
      <Skeleton
        baseColor="#cccccc"
        style={{
          opacity: 0.5,
          width: "15%",
          borderRadius: "20px",
          height: "4rem",
          marginTop: "5.5rem",
        }}
      />
    </>
  );
};
