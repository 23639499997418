import React from "react";
import { CustomSpinner } from "../../../components/Loader/custom.spinner";
import { setSenceSession } from "../../../utils/cookies";

export const SenceLogout = () => {
  React.useEffect(() => {
    if (window.location.search) {
      let session = setSenceSession(false);
      if (session) {
        localStorage.removeItem("last_login_sence");
        localStorage.removeItem("sence_session");
        localStorage.removeItem("current_course");
        localStorage.removeItem("idSence");
        localStorage.removeItem("nru");
        window.location.replace(`/listcourses`);
      }
    }
  }, []);

  return <CustomSpinner />;
};
