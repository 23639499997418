import axios from "axios";

export const getCurrentUser = async ({ token, username, baseUrl }) => {
  const resp = await axios.get(
    `${baseUrl}/webservice/rest/server.php?wstoken=${token}&wsfunction=core_user_get_users_by_field&field=username&values[0]=${username}&moodlewsrestformat=json`
  );
  if (resp.data.errorcode) {
    return resp.data;
  }
  const user = resp.data[0];
  return { ...user, token };
};

const login = async ({ username, password, baseUrl }) => {
  const respAuth = await axios.get(
    `${baseUrl}/login/token.php?username=${username}&password=${password}&service=moodle_mobile_app`
  );
  // console.log({ respAuth });
  const data = respAuth.data;
  if (data.error) {
    throw new Error(data.error);
  }
  const { token } = data;
  return { data, token };
};

export const requestAutoLogin = async ({ username, baseUrl, tipddytoken }) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}/webservice/rest/server.php?wstoken=${tipddytoken}&moodlewsrestformat=json&wsfunction=auth_userkey_request_login_url&user[username]=${username}`
    );
    console.log({ requestAutoLogin: data });
    let key = data.loginurl.split("=")[1];
    return key;
  } catch (e) {
    throw new Error(e);
  }
};

export const loginMoodle = async (payload) => {
  let { baseUrl, credentials } = payload;
  let { username, password } = credentials;
  console.log({ baseUrl });
  try {
    const { token } = await login({ username, password, baseUrl });
    const user = await getCurrentUser({ token, username, baseUrl });
    // console.log({ user });
    localStorage.setItem("token", token);
    localStorage.setItem("username", username);
    return { ...user, token };
  } catch (e) {
    throw new Error(e);
  }
};

export const resetPasword = async ({ args, baseUrl }) => {
  console.log({ args });
  try {
    const { data } = await axios.post(
      `${baseUrl}tipddy/admin/iapp/services/restore-password.php/user/changepasswordforce`,
      { ...args },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return data;
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export const authMiddleware = async ({ token, username, baseUrl }) => {
  try {
    const user = await getCurrentUser({ token, username, baseUrl });
    if (user.errorcode) {
      localStorage.setItem("username", username);
      localStorage.setItem("token", token);
      return user;
    }
    localStorage.setItem("token", token);
    localStorage.setItem("username", username);
    return { ...user, token };
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
};
