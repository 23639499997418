/**
 *
 * @param {evt.target} e
 * @param {*} state
 * @param {Function} setState
 * @returns
 */

export const defaultHandleChange = (e, state, setState) => {
  return setState({
    ...state,
    [e.target.name]: e.target.value,
  });
};

export const defaultTabHandler = (type) => {
  if (type === "files") {
    document.querySelector(".files").classList.add("bot-avaluacion-3-activo");
    document.querySelector(".files").classList.remove("bot-avaluacion-3");
    document
      .querySelector(".lessons")
      .classList.remove("bot-avaluacion-3-activo");
    document.querySelector(".lessons").classList.add("bot-avaluacion-3");
  } else {
    document.querySelector(".lessons").classList.add("bot-avaluacion-3-activo");
    document.querySelector(".lessons").classList.remove("bot-avaluacion-3");
    document
      .querySelector(".files")
      .classList.remove("bot-avaluacion-3-activo");
    document.querySelector(".files").classList.add("bot-avaluacion-3");
  }
};

export const defaultSenceValidation = (current) => {
  if (current.idnumber.includes("sence")) {
    let id = current.idnumber.split("sence-")[1];
    return {
      isSence: true,
      idSence: id,
    };
  }
  return {
    isSence: false,
  };
};

/* export const defaultSenceValidation = (current) => {
  if(current.idnumber !== ''){
    return {
      isSence: true,
      idSence: current.idnumber
    }
  }
  return {
    isSence: false
  }
} */
