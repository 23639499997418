import React from "react";
// import logo_footer from "./assets/logo-footer3.png";
import ico_face from "./assets/ico-face.png";
import ico_you from "./assets/ico-you.png";
import ico_inst from "./assets/ico-inst.png";
import logo_tipddy from "./assets/logo-tipddy.png";
import logo_tipddy_dark from "./assets/logo-tipddy-dark.png";
import "./index.css";
import { useTheme } from "../../contexts/ThemeContext";
import { useConfig } from "../../contexts/config";
import { readableColor } from "polished";

export const Footer = () => {
  const { config } = useConfig();
  const theme = useTheme();
  const [contrastColorSecondary, setcontrastColorSecondary] =
    React.useState("green");

  React.useEffect(() => {
    if (theme && theme.palette.secondary) {
      setcontrastColorSecondary(
        readableColor(theme.palette.secondary, "#000", "#fff", false)
      );
    }
    // eslint-disable-next-line
  }, [theme]);

  return (
    <>
      <div
        className="wrapper fon-footer sale-movil"
        style={{
          padding: "7.5rem",
          backgroundColor: theme.palette.secondary,
          color: contrastColorSecondary,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-3 mx-5">
              <img src={config.config.theme.logo} alt="..." className="" />
              <br />
              <br />
            </div>

            <div className="col-md-3">
              <p
                className="tit-footer"
                style={{ color: contrastColorSecondary }}
              >
                Accesos
              </p>
              <ul className="esp-footer ul-col">
                <li
                  className="menu-footer"
                  style={{ color: contrastColorSecondary }}
                >
                  Inicio
                </li>
                <li
                  className="menu-footer"
                  style={{ color: contrastColorSecondary }}
                >
                  Historial
                </li>{" "}
              </ul>
            </div>
            <div className="col-md-3" style={{ marginLeft: "auto" }}>
              <p
                className="tit-footer"
                style={{ color: contrastColorSecondary }}
              >
                Contacto
              </p>
              <ul className="esp-footer">
                <li className="menu-footer-icon">
                  <a
                    href="https://www.tipddy.cl/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={ico_face} alt="..." className="img-rounded" />
                  </a>
                </li>
                <li className="menu-footer-icon">
                  <a
                    href="https://www.tipddy.cl/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={ico_you} alt="..." className="img-rounded" />
                  </a>
                </li>
                <li className="menu-footer-icon">
                  <a
                    href="https://www.tipddy.cl/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={ico_inst} alt="..." className="img-rounded" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="wrapper fon-pie  sale-movil"
        style={{
          backgroundColor: theme.palette.secondary,
          color: contrastColorSecondary,
        }}
      >
        <p
          className="tipddy m-0 py-5 px-5"
          style={{ color: contrastColorSecondary }}
        >
          <img
            src={
              contrastColorSecondary === "#fff" ? logo_tipddy : logo_tipddy_dark
            }
            alt="..."
            className="img-rounded"
          />
          Copyright Powered by Tipddy
        </p>
      </div>

      <div className="wrapper fon-pie2 sale-desktop entra-movil">
        <img src={config.config.theme.logo} alt="..." className="logo-tipddy" />
      </div>

      <div className="wrapper fon-pie3 sale-desktop entra-movil">
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-6">
              <p
                className="tit-footer"
                style={{ color: contrastColorSecondary }}
              >
                Accesos
              </p>
              <ul className="esp-footer">
                <li
                  className="menu-footer"
                  style={{ color: contrastColorSecondary }}
                >
                  Inicio
                </li>
                <li
                  className="menu-footer"
                  style={{ color: contrastColorSecondary }}
                >
                  Historial
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="wrapper tipddy-p fon-pie sale-desktop entra-movil"
        style={{
          backgroundColor: theme.palette.secondary,
          color: contrastColorSecondary,
        }}
      >
        <p className="tipddy2" style={{ color: contrastColorSecondary }}>
          <img
            src={
              contrastColorSecondary === "#fff" ? logo_tipddy : logo_tipddy_dark
            }
            alt="..."
            className="img-rounded"
          />
          Copyright Powered by Tipddy
        </p>
      </div>
    </>
  );
};
