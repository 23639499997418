import axios from "axios";

let baseUrl = "https://www.tipddy.cl/wp-json/v2/public";

export const wordpresstoken = async () => {
  try {
    let { data } = await axios.post(
      "https://www.tipddy.cl/wp-json/jwt-auth/v1/token",
      {
        username: "bill",
        password: "A_gu_23da_DATA",
      },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return data.token;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchClientsIds = async ({ token }) => {
  try {
    let { data } = await axios.get(`${baseUrl}/clients`, {
      headers: {
        Authorization: `Bearer ${token}}`,
      },
    });
    return data.result;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchConfig = async ({ id, token }) => {
  try {
    let { data } = await axios.get(`${baseUrl}/client/${id}`, {
      headers: {
        Authorization: `Bearer ${token}}`,
      },
    });

    return data.result;
  } catch (err) {
    throw new Error(err);
  }
};
