export const DEFAULT_CREDENTIALS = {
  username: "",
  password: "",
};

export const DEFAULT_FORGOT_CREDENTIALS = {
  username: "",
  email: "",
};

export const DEFAULT_CHANGE_PASSWORD_CREDENTIALS = {
  password: "",
  confirm_password: "",
};

export const DEFAULT_HEADER_DATA = {
  text: "",
  img: "",
  course: false,
};

export const DEFAULT_VISUAL_CONTENT = {
  type: null,
  content: "default",
};

export const MIME_TYPES = {
  png: "image/png",
  pdf: "application/pdf",
  xslx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ppt: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
};

export const MOODLE = {
  forum: "forum",
  folder: "folder",
  label: "label",
  page: "page",
  zoom: "zoom",
  assign: "assingn",
  chat: "chat",
  resource: "resource",
  feedback: "feedback",
  quiz: "quiz",
  url: "url",
  scorm: "scorm",
};

export const TIPDDY = {
  assign: "assign",
  video: "video",
  vimeo: "vimeo",
  pdf: "pdf",
  folder: "folder",
  forum: "forum",
  zoom: "zoom",
  teamsmicrosoft: "teamsmicrosoft",
  meetgoogle: "meetgoogle",
  chat: "chat",
  page: "page",
  xslx: "xslx",
  doc: "doc",
  urlexternal: "url.external",
  label: "label",
  title: "title",
  feedback: "feedback",
  quiz: "quiz",
  scorm: "scorm",
  diagnostic: "Evaluación diagnóstica",
  summary: "summary",
  h5p: "h5p",
};

export const MOODLE_NAMES = {
  etiqueta: "Etiqueta",
};

export const TIPDDY_RESOURCE = {
  files: "files",
  lessons: "lessons",
  diagnostic: "Evaluación diagnóstica",
};

export const PROGRESS_DEFAULT = {
  title: "Historial",
  headers: [
    "Cursos Realizados",
    "Notas Finales",
    "Inicio",
    "Termino",
    "Certificado",
    "Enc. de Satisfaccion",
  ],
};

export const PROGRESS_DETAIL = {
  title: "Detalle",
  headers: [
    "Evaluaciones",
    "Promedio calculado",
    "Calificacion",
    "Rango",
    "Porcentaje",
    "Retroalimentacion",
  ],
};

export const LIMIT_CRON = 9000;
export const LIMIT_CRON_AVG = 8990;
export const LIMIT_CRON_DIALOG = 8880;

/* export const LIMIT_CRON = 120;
export const LIMIT_CRON_AVG = 110;
export const LIMIT_CRON_DIALOG = 90; */
