import React from "react";
import { useTheme } from "../../../contexts/ThemeContext";
import { THEME_CONTEXT } from "../../../utils/theme";

export const HeaderProgress = () => {
  const theme = useTheme();
  return (
    <>
      <div style={{ paddingTop: 20 }}></div>
      <h1 className="historial" style={theme.styles.title_text}>
        Progreso
      </h1>
      <ul className="progress-list mt-5">
        <li className="progress-list" style={theme.styles.title_text}>
          <div
            className="square"
            style={{ backgroundColor: THEME_CONTEXT.NAV_LIGHT }}
          ></div>
          &nbsp;Completado
        </li>
        <li className="progress-list" style={theme.styles.title_text}>
          <div
            className="square"
            style={{ backgroundColor: THEME_CONTEXT.DAY_MODE_BTN }}
          ></div>
          &nbsp;Pendiente
        </li>
      </ul>
    </>
  );
};
