import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const Suspense = () => {
  let styles = {};

  let height = "5rem";

  let circleWidth = "50px";

  let circleRadius = "50%";

  let reactWidth = "235px";

  styles.row = {
    display: "flex",
    justifyContent: "center",
    marginTop: ".5rem",
  };

  return (
    <>
      <div style={styles.row}>
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: circleWidth,
            height: height,
            borderRadius: circleRadius,
            marginRight: "1rem",
          }}
        />
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: reactWidth,
            height: height,
            borderRadius: "12px",
          }}
        />
      </div>
      <div style={styles.row}>
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: circleWidth,
            height: height,
            borderRadius: circleRadius,
            marginRight: "1rem",
          }}
        />
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: reactWidth,
            height: height,
            borderRadius: "12px",
          }}
        />
      </div>
      <div style={styles.row}>
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: circleWidth,
            height: height,
            borderRadius: circleRadius,
            marginRight: "1rem",
          }}
        />
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: reactWidth,
            height: height,
            borderRadius: "12px",
          }}
        />
      </div>
      <div style={styles.row}>
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: circleWidth,
            height: height,
            borderRadius: circleRadius,
            marginRight: "1rem",
          }}
        />
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: reactWidth,
            height: height,
            borderRadius: "12px",
          }}
        />
      </div>
    </>
  );
};
