import React from "react";
import {
  getCurrentUser,
  loginMoodle /* requestAutoLogout */,
} from "../services/moodle/userauth";
import { useConfig } from "./config";
import { STRINGS } from "../utils/strings";

export const AuthContext = React.createContext();

/* actions to work on */
export const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const { config } = useConfig();
  const [verified, setVerified] = React.useState(false);

  const actions = {
    RefreshAuth: async () => {
      try {
        const token = localStorage.getItem("token");
        const username = localStorage.getItem("username");
        const user = await getCurrentUser({
          token,
          username,
          baseUrl: config.baseURL,
        });
        // console.log({ user });
        setUser(user);
        setVerified(true);
      } catch (e) {
        throw new Error(e);
      }
    },
    Authenticate: async () => {
      if (localStorage.getItem("token") && !user) {
        /*         console.log('refreshtoken') */
        return Promise.resolve(await actions.RefreshAuth());
      }
      setVerified(true);
    },
    Login: async function (credentials) {
      // console.log({ config });
      try {
        const moodleUserInfo = await loginMoodle({
          credentials,
          baseUrl: config.baseURL,
        });
        console.log({ moodleUserInfo });
        setUser(moodleUserInfo);
        setVerified(true);

        if (moodleUserInfo.errorcode === STRINGS.FORCEPASSWORD_ERROR_CODE) {
          localStorage.setItem(
            STRINGS.FORCEPASSWORD_ERROR_CODE,
            STRINGS.FORCEPASSWORD_ERROR_CODE
          );
        }
      } catch (e) {
        throw new Error(e.message);
      }
    },
    logout: (moodle = null) => {
      if (user && user.id) {
        localStorage.setItem("newaccess", user.id);
      }

      setUser(null);
      if (moodle.categories || moodle.courses || moodle.grades) {
        moodle.setCourses(null);
        moodle.setCategories(null);
        moodle.setGrades(null);
      }
      localStorage.removeItem(STRINGS.FORCEPASSWORD_ERROR_CODE);
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      localStorage.removeItem("username");
      localStorage.removeItem("last-access-cmid");
      localStorage.removeItem("sence_session");
      localStorage.removeItem("current_course");
      localStorage.removeItem("last_login_sence");
      localStorage.removeItem("darkMode");
      localStorage.removeItem("idSence");
      localStorage.removeItem("nru");
      // window.location.replace("/");
    },
  };

  const value = {
    user,
    verified,
    setUser,
    Authenticate: actions.Authenticate,
    Login: actions.Login,
    logout: actions.logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);
