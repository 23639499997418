const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

// Recibir todos los mensajes
// Insertar la fecha inicial de los mensajes
// Chequear cuando la fecha cambia e insertar la fecha en el array
export function messagesWithDate(messages) {
  for (let idx = 0; idx < messages.length - 1; idx++) {
    if (idx === 0) {
      messages[idx].hastime = timeCreated(messages[idx].timecreated);
    }

    let firstDate = timeCreated(messages[idx].timecreated).data;
    let secondDate = timeCreated(messages[idx + 1].timecreated).data;

    if (firstDate !== secondDate) {
      messages[idx + 1].hastime = timeCreated(messages[idx + 1].timecreated);
    }
  }

  return messages;
}

export function timeCreated(time) {
  let date = new Date(time * 1000);
  let month = date.getMonth();
  let day = date.getDate();

  let Month = MONTHS[month];

  return {
    data: `${day} de ${Month}`,
  };
}
