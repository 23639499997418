import axios from "axios";

export const setActivityProgressCompletedManually = async (args) => {
  try {
    const { token, cmid, completed, baseURL } = args;
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_completion_update_activity_completion_status_manually&cmid=${cmid}&completed=${completed}`
    );
    /*     console.log(data) */
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
