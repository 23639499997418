import axios from "axios";

export const fetchNotifications = async ({ token, baseURL }) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=message_popup_get_popup_notifications&useridto=0`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const fetchNotificationsCount = async ({ token, baseURL }) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=message_popup_get_unread_popup_notification_count&useridto=0`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
