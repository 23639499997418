import "./index.css";
import React from "react";
import { Chats } from "./chats";
import { Contacts } from "./contacts";
import { Tabs } from "./tabs";
import { useAuth } from "../../contexts/AuthContext";
import { Conversation } from "./conversation";
import { UserOptions } from "./user-options";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useMoodleV2 } from "../../contexts/moodle";
import close_btn from "./assets/cerrar.png";

const tabs = [
  {
    key: 0,
    name: "chats",
  },
  {
    key: 1,
    name: "contacts",
  },
  {
    key: 2,
    name: "conversation",
  },
  {
    key: 3,
    name: "user",
  },
];

export const MessagesApp = ({ handleMessagesApp, badge, setBadge }) => {
  const auth = useAuth();
  const [contacts, setContacts] = React.useState(null);
  const [messages, setMessages] = React.useState(null);
  const [currentChatId, setCurrentChatId] = React.useState(null);
  const [chats, setChats] = React.useState(null);
  const [viewselected, setView] = React.useState(0);
  const [tabsShown, showTabs] = React.useState(true);
  const [currentChatData, setCurrentChatData] = React.useState(null);
  const [currentTalkerMember, setCurrentTalkerMember] = React.useState(null);

  const openChat = (chat) => {
    setCurrentTalkerMember(null);
    setMessages(null);
    setView(2);
    setCurrentChatId(chat.id);
    setCurrentChatData(chat);
  };

  const createChat = (user) => {
    setCurrentChatData(null);
    setMessages(null);
    setView(2);
    setCurrentTalkerMember(user);
    setCurrentChatId(null);
  };

  if (!auth.user) {
    return null;
  }

  return (
    <div className="sidebar-container-messages">
      <img
        src={close_btn}
        alt="..."
        className="close-btn-msg"
        onClick={handleMessagesApp}
      />
      {tabsShown && (
        <Tabs setView={setView} selected={tabs[viewselected].key} />
      )}
      <Contacts
        selected={tabs[viewselected].name}
        contacts={contacts}
        setContacts={setContacts}
        createChat={createChat}
      />
      <Chats
        selected={tabs[viewselected].name}
        chats={chats}
        setChats={setChats}
        setMessages={setMessages}
        openChat={openChat}
        auth={auth}
      />
      <Conversation
        selected={tabs[viewselected].name}
        messages={messages}
        setMessages={setMessages}
        chatid={currentChatId}
        setCurrentChatId={setCurrentChatId}
        showTabs={showTabs}
        setView={setView}
        talker={currentTalkerMember}
        talkingto={setCurrentTalkerMember}
        auth={auth}
        setChats={setChats}
        currentChatData={currentChatData}
      />
      <UserOptions
        selected={tabs[viewselected].name}
        talker={currentTalkerMember}
        setView={setView}
        showTabs={showTabs}
        setChats={setChats}
      />
      <MessageButton
        onClick={handleMessagesApp}
        chats={chats}
        badge={badge}
        setBadge={setBadge}
      />
    </div>
  );
};

const MessageButton = ({ onClick, chats, badge, setBadge }) => {
  const moodle = useMoodleV2();

  React.useEffect(() => {
    moodle &&
      moodle
        .getUnreadConversationsCount()
        .then((res) => {
          setBadge(res);
        })
        .catch((err) => {
          console.log(err);
        });
    // eslint-disable-next-line
  }, [chats]);

  let styles = {};

  styles.badge = {
    position: "absolute",
    backgroundColor: "red",
    color: "#fff",
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    top: 0,
    right: 0,
  };

  return (
    <div className="msg-btn-desk" onClick={onClick}>
      {badge > 0 && (
        <div className="msg-badge" style={styles.badge}>
          {badge}
        </div>
      )}
      <TelegramIcon
        className="burger-messages"
        sx={{ color: "#fff", fontSize: 45, marginRight: "5px" }}
      />
    </div>
  );
};
