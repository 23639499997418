import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import "./index.css";

const COLOR_UNREAD_ICON = "#0079ac";
const COLOR_READ_ICON = "#b2b2b2";

export const Notifications = ({
  noticount,
  notifications,
  hoverBell,
  opennotif,
  openNotifications,
}) => {
  return (
    <div>
      {noticount > 0 && (
        <div className="notification-medal">
          <p>{noticount}</p>
        </div>
      )}
      <div
        style={{
          transition: ".5s",
          position: "fixed",
          width: "320px",
          height: "100vh",
          backgroundColor: "#d6eef5",
          right: opennotif ? "-10px" : "-350px",
          top: "0px",
          zIndex: "1000",
          boxShadow:
            "rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px",
        }}
      >
        <div className="noti-tabContainer">
          <p className="noti-title">Notificaciones</p>
          <IconButton
            onClick={openNotifications}
            sx={{
              position: "absolute",
              top: 2,
              right: 20,
            }}
          >
            <CloseIcon
              sx={{
                fontSize: 20,
                color: "#fff",
              }}
            />
          </IconButton>
        </div>
        {notifications &&
          notifications.length > 0 &&
          notifications.map((n, idx) => {
            return (
              <div
                key={idx}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid #b2b2b2",
                  margin: 0,
                  justifyContent: "space-between",
                  padding: "1.1rem .7rem",
                  height: 95,
                  backgroundColor: n.read ? "unset" : "#cfd8dc",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 15,
                    alignItems: "center",
                  }}
                >
                  <InfoIcon
                    sx={{
                      color: n.read ? COLOR_READ_ICON : COLOR_UNREAD_ICON,
                      fontSize: 18,
                      marginTop: "1px",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#605454",
                      margin: 0,
                      fontWeight: n.read ? "unset" : 500,
                    }}
                  >
                    {n.subject}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <p
                    style={{
                      fontSize: "10px",
                      color: "#0079ac",
                      marginLeft: 0,
                      fontWeight: 500,
                    }}
                  >
                    {n.timecreatedpretty}
                  </p>
                  <p
                    style={{
                      fontSize: "10px",
                      color: "#0079ac",
                      marginLeft: 10,
                      fontWeight: 500,
                    }}
                  >
                    Ver notificacion completa
                  </p>
                </div>
              </div>
            );
          })}
      </div>
      <NotificationsIcon
        className="notification-icon"
        onClick={openNotifications}
        sx={{
          color: hoverBell ? "#b2b2b2" : "#fff",
        }}
      />
      <div className="noti-mob-text">Notificaciones</div>
    </div>
  );
};
