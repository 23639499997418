import { useEffect, useState } from "react";
import back from "../conversation/arrow-left.svg";
import check from "../assets/check2-circle.svg";
import { useMoodleV2 } from "../../../contexts/moodle";

export const UserOptions = ({
  selected,
  talker,
  setView,
  showTabs,
  setChats,
}) => {
  const moodle = useMoodleV2();
  const [isContactRequestLoading, setContactRequestLoading] = useState(false);
  const [isContactRequestPending, setContactRequestPending] = useState(false);
  const [needsFetching, setNeedsFetching] = useState(false);
  const [isBlocked, setBlocked] = useState(null);
  const [isBlockingLoading, setBlockingLoading] = useState(false);
  let isSelectedView = selected === "user";

  useEffect(() => {
    if (talker) {
      setBlocked(talker.isblocked);
    }
    return () => {
      setContactRequestLoading(false);
      setContactRequestPending(false);
      setNeedsFetching(false);
    };
  }, [talker]);

  const handleBackNavigation = () => {
    if (needsFetching) {
      setChats(null);
    }
    showTabs(true);
    setView(0);
  };

  const handleBlockUser = async () => {
    setBlockingLoading(true);
    moodle
      .blockUser({ blockeduserid: talker.id })
      .then((res) => {
        setTimeout(() => {
          setBlockingLoading(false);
          setNeedsFetching(true);
          setBlocked(true);
        }, 1000);
      })
      .catch((err) => {
        setBlockingLoading(false);
      });
  };

  const handleContactRequest = async () => {
    setContactRequestLoading(true);
    moodle
      .sendContactRequest({ requesteduserid: talker.id })
      .then((res) => {
        setTimeout(() => {
          setContactRequestLoading(false);
          setContactRequestPending(true);
          setNeedsFetching(true);
        }, 1000);
      })
      .catch((err) => {
        setContactRequestLoading(false);
        console.log(err);
      });
  };

  const handleUnblockUser = async () => {
    setBlockingLoading(true);
    moodle
      .unblockUser({ unblockeduserid: talker.id })
      .then((res) => {
        setBlockingLoading(false);
        setNeedsFetching(true);

        setBlocked(false);
      })
      .catch((err) => {
        console.log(err);
        setBlockingLoading(false);
      });
  };

  if (!isSelectedView) {
    return null;
  }

  if (isSelectedView) {
    return (
      <>
        <img
          alt="..."
          src={back}
          onClick={handleBackNavigation}
          style={{
            width: 30,
            position: "absolute",
            top: 30,
            left: 20,
            cursor: "pointer",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <img
            alt="..."
            src={talker.profileimageurl}
            style={{
              borderRadius: "50%",
              marginBottom: "1.5rem",
              border: "4px solid rgb(0, 121, 172)",
            }}
          />
          <p style={{ fontSize: 20 }}>{talker.fullname}</p>
          {isContactRequestLoading ? (
            <button
              class="btn btn-large"
              type="button"
              disabled
              style={{
                width: "50%",
                marginTop: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                backgroundColor: "rgb(0, 121, 172)",
              }}
            >
              <p style={{ fontSize: 14 }}> Enviando...</p>
              <span
                class="spinner-border spinner-border"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          ) : isContactRequestPending ? (
            <button
              class="btn  btn-large"
              type="button"
              disabled
              style={{
                width: "50%",
                marginTop: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                backgroundColor: "rgb(0, 121, 172)",
              }}
            >
              <p style={{ fontSize: 14 }}> Pendiente</p>
              <img alt="..." src={check} />
            </button>
          ) : (
            <button
              onClick={handleContactRequest}
              className="btn btn-large "
              style={{
                width: "50%",
                marginTop: "1rem",
                backgroundColor: "rgb(0, 121, 172)",
                color: "#fff",
              }}
            >
              Añadir a contactos
            </button>
          )}

          {isBlockingLoading ? (
            <button
              disabled
              className="btn btn-large btn-danger"
              style={{ width: "50%", marginTop: "1rem" }}
            >
              Cargando...
            </button>
          ) : isBlocked ? (
            <button
              onClick={handleUnblockUser}
              className="btn btn-large btn-danger"
              style={{ width: "50%", marginTop: "1rem" }}
            >
              Desbloquear usuario
            </button>
          ) : (
            <button
              onClick={handleBlockUser}
              className="btn btn-large btn-danger"
              style={{ width: "50%", marginTop: "1rem" }}
            >
              Bloquear usuario
            </button>
          )}
        </div>
      </>
    );
  }
};
