import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const Suspense = () => {
  let styles = {};

  let height = "5rem";

  let circleWidth = "60px";

  let circleRadius = "50%";

  let reactWidth = "235px";

  styles.row = {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.5rem",
  };

  return (
    <>
      <RowSkeleton
        css={styles.row}
        circleRadius={circleRadius}
        circleWidth={circleWidth}
        reactWidth={reactWidth}
      />

      <RowSkeleton
        css={styles.row}
        circleRadius={circleRadius}
        circleWidth={circleWidth}
        reactWidth={reactWidth}
      />
      <RowSkeleton
        css={styles.row}
        circleRadius={circleRadius}
        circleWidth={circleWidth}
        reactWidth={reactWidth}
      />

      <RowSkeleton
        css={styles.row}
        circleRadius={circleRadius}
        circleWidth={circleWidth}
        reactWidth={reactWidth}
      />
    </>
  );
};

const RowSkeleton = ({ css, circleRadius, circleWidth, reactWidth }) => {
  return (
    <div style={css}>
      <Skeleton
        baseColor="#adb5bd"
        style={{
          opacity: 0.5,
          width: circleWidth,
          height: "60px",
          borderRadius: circleRadius,
          marginRight: "1rem",
        }}
      />
      <div>
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: reactWidth,
            height: 15,
            borderRadius: "5px",
          }}
        />
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: "150px",
            height: 15,
            borderRadius: "5px",
          }}
        />
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.5,
            width: "130px",
            height: 15,
            borderRadius: "5px",
          }}
        />
      </div>
    </div>
  );
};
