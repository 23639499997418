import "./index.css";
import avatar from "./assets/person-circle.svg";
import logout from "./assets/box-arrow-right.svg";
import close_btn from "./assets/cerrar2.png";
import home from "./assets/house-fill.svg";
// import brand from "./assets/log-menu.png";
import grades from "./assets/mortarboard-fill.svg";
// import msg from "./assets/chat-dots-fill.svg";
// import resume from "./assets/collection-play-fill.svg";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useMoodleV2 } from "../../contexts/moodle";
import { CustomDialog } from "../Dialog";
import { useConfig } from "../../contexts/config";
import { Notifications } from "../Notifications";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { useTheme } from "../../contexts/ThemeContext";
import TelegramIcon from "@mui/icons-material/Telegram";

export const Sidebar = ({
  sidebar,
  toggleSidebar,
  handleCalendar,
  eventsCount,
  noticount,
  notifications,
  hoverBell,
  opennotif,
  setOpenNotif,
  handleDarkMode,
  handleMessagesApp,
  badge,
}) => {
  const { config } = useConfig();

  // console.log({ config });

  const [open, setOpen] = React.useState(false);
  const [, setCourse] = React.useState(null);
  const moodle = useMoodleV2();
  const auth = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const sidebar_container = React.useRef(null);

  const handleLogoutCompleted = () => {
    setOpen(false);
    auth.logout(moodle);
    navigate("/");
  };

  const handleLogout = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (moodle.currentCourse) {
      setCourse(moodle.currentCourse);
    }
  }, [moodle.currentCourse]);

  // const handleSidebar = () => {
  //   let { style } = document.querySelector(".sidebar-container");

  //   style.left = "-320px";
  // };

  const handleSidebar = () => {
    let { style } = document.querySelector(".sidebar-container");
    if (!sidebar) {
      style.left = "0";
      return toggleSidebar(!sidebar);
    }

    style.left = "-350px";
    toggleSidebar(!sidebar);
  };

  const handleMsgSidebar = () => {
    handleSidebar();
    handleMessagesApp();
  };

  const handleCalendarSidebar = () => {
    handleSidebar();
    handleCalendar();
  };

  const openNotifications = () => {
    sidebar && handleSidebar();
    setOpenNotif(!opennotif);
  };

  // React.useEffect(() => {
  //   console.log(
  //     sidebar,
  //     sidebar_container,
  //     sidebar_container?.current?.offsetLeft === 0
  //   );
  //   if (
  //     sidebar &&
  //     sidebar_container &&
  //     sidebar_container.current.offsetLeft === 0
  //   ) {
  //     console.log("entro if cierre sidebar");
  //     document.addEventListener("click", (e) => handleSidebarClose2(e));
  //   }
  //   return () => {
  //     window.removeEventListener("click", (e) => handleSidebarClose2(e));
  //   };
  //   //eslint-disable-next-line
  // }, [sidebar, sidebar_container, sidebar_container?.current?.offsetLeft]);
  // const handleSidebarClose2 = (event) => {
  //   event.preventDefault();
  //   let componente = sidebar_container; // Reemplaza 'id_del_componente' con el ID de tu componente
  //   let { style } = document.querySelector(".sidebar-container");
  //   // Verifica si el clic ocurrió fuera del componente
  //   if (componente && !componente.current.contains(event.target)) {
  //     // El clic ocurrió fuera del componente, ejecuta la función
  //     console.log("cerrando navbar");
  //     style.left = "-350px";
  //     toggleSidebar(!sidebar);
  //   }
  // };

  return (
    <div className="sidebar-container" ref={sidebar_container}>
      <div className="top">
        <div className="top-content">
          <img src={logout} alt="..." onClick={handleLogout} />
          <img src={avatar} className="avatar" alt="..." />
          <div
            className="modo_dia_mob"
            onClick={handleDarkMode}
            style={theme.styles.darkmodebtn}
          >
            {theme.mode === "light" ? (
              <LightModeIcon sx={{ fontSize: 22 }} />
            ) : (
              <DarkModeIcon sx={{ fontSize: 22 }} />
            )}
          </div>
          <img
            src={close_btn}
            alt="..."
            className="close-btn"
            onClick={handleSidebar}
          />
        </div>
      </div>
      <div className="center">
        <div className="center-content">
          <ul>
            <Link to={"/listcourses"} onClick={handleSidebar}>
              <li>
                <img src={home} alt="..." />
                Inicio
              </li>
            </Link>
            <Link to={"/listcourses/progress"} onClick={handleSidebar}>
              <li>
                {" "}
                <img src={grades} alt="..." />
                Historial
              </li>
            </Link>
            {/* <Link to={"/contact"} onClick={handleSidebar}>
              <li>
                {" "}
                <img src={msg} alt="..." />
                Contacto
              </li>
            </Link> */}
            {/* {course && (
              <Link to={"/contact"} onClick={handleSidebar}>
              <li>
              {" "}
              <img src={resume} alt="..." />
              Continuar con {course.shortname}
              </li>
              </Link>
            )} */}
            <li style={{ marginBottom: "2rem" }}>
              <div
                style={{
                  marginRight: 20,
                  position: "relative",
                  cursor: "pointer",
                }}
                onClick={handleCalendarSidebar}
              >
                {eventsCount > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "-7px",
                      right: "-17px",
                      backgroundColor: "red",
                      color: "#fff",
                      height: 20,
                      width: 20,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      transition: ".3s",
                    }}
                  >
                    <p>{eventsCount}</p>
                  </div>
                )}
                <CalendarMonthIcon
                  sx={{
                    color: "#fff",
                    fontSize: 28,
                    transition: ".3s",
                    marginRight: ".5rem",
                    paddingRight: ".4rem",
                  }}
                />
                Calendario
              </div>
            </li>
            <li style={{ marginBottom: "2rem" }}>
              <div
                style={{
                  marginRight: 20,
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <Notifications
                  noticount={noticount}
                  notifications={notifications}
                  hoverBell={hoverBell}
                  opennotif={opennotif}
                  openNotifications={openNotifications}
                />
              </div>
            </li>
            <li style={{ marginBottom: "2rem" }}>
              <div
                style={{
                  marginRight: 20,
                  position: "relative",
                  cursor: "pointer",
                }}
                onClick={handleMsgSidebar}
              >
                {badge > 0 && <div className="msg-badge">{badge}</div>}
                <TelegramIcon
                  className="burger-messages"
                  sx={{ color: "#fff", fontSize: 28, marginRight: "5px" }}
                />
                Mensajes
              </div>
            </li>
            {/* <li style={{ marginBottom: "2rem" }}>
              <div
                className="modo_dia_mob"
                onClick={handleDarkMode}
                style={theme.styles.darkmodebtn}
              >
                {theme.mode === "light" ? (
                  <LightModeIcon sx={{ fontSize: 22 }} />
                ) : (
                  <DarkModeIcon sx={{ fontSize: 22 }} />
                )}
              </div>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="bottom">
        {/*         <div>
          <img src={brand} alt="..." />
        </div> */}
        <div>
          <img src={config.config.theme.logo} alt="..." />
        </div>
      </div>
      <CustomDialog
        open={open}
        title={"Confirmar cerrar sesión"}
        message={
          "Si desea cerrar sesión seleccione confirmar, de lo contrario puedes cancelar esta acción"
        }
        onCancel={() => setOpen(false)}
        onConfirm={handleLogoutCompleted}
      />
    </div>
  );
};
