import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
// import icon from "./log-menu.png";
import { useConfig } from "../../contexts/config";
export const CustomDialog = ({
  open,
  onConfirm,
  onCancel,
  /* title, */
  message,
  confirm = "Confirmar",
  cancel = "Cancelar",
}) => {
  const { config } = useConfig();

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth={"xs"}
        PaperProps={{
          style: {
            backgroundColor: config.config.theme.colors.secondary,
            boxShadow: "none",
            paddingBottom: ".7rem",
            paddingRight: ".9rem",
          },
        }}
      >
        <DialogTitle
          className="text-white"
          sx={{ fontSize: "2rem", fontFamily: "Pop-Regular" }}
        >
          <img
            src={config.config.theme.logo}
            style={{ width: 90, marginTop: "1rem" }}
            alt={"..."}
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className="text-white"
            sx={{
              fontSize: "1.6rem",
              fontFamily: "Pop-Regular",
              marginLeft: ".2rem",
            }}
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onConfirm}
            variant="contained"
            sx={{
              fontSize: "1.4rem",
              fontFamily: "Pop-Medium",
              color: "#fff",
              backgroundColor: "#0da1bf",
            }}
          >
            {confirm}
          </Button>
          <Button
            onClick={onCancel}
            variant="contained"
            sx={{
              fontSize: "1.4rem",
              fontFamily: "Pop-Medium",
              color: "#fff",
              backgroundColor: "#0da1bf",
            }}
          >
            {cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
