import axios from "axios";
import { moodleURL, tipddytoken } from "../apirest";

/* #core_enrol_get_users_courses */

export const getUserCourses = async ({ token, userid, baseUrl }) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_enrol_get_users_courses&userid=${userid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getCourseUsersInfo = async (args) => {
  try {
    const { token, course_id } = args;
    const { data } = await axios.get(
      `${moodleURL}${token}&moodlewsrestformat=json&wsfunction=core_group_get_course_user_groups&courseid=${course_id}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getUsers = async (token, baseUrl) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}/${tipddytoken}&moodlewsrestformat=json&wsfunction=core_user_get_users&criteria[0][key]=lastname&criteria[0][value]=%`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

/* COURSE GRADES OVERVIEW */

export const getOverviewGrades = async (token, baseURL) => {
  try {
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=gradereport_overview_get_course_grades`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
/* gradereport_overview_get_course_grades */

/* export const getActivityGrades = async (args) => {
  try {
    const { token, cmid, courseid, userid } = args;
    const { data } = await axios.get(
      `${moodleURL}${token}&moodlewsrestformat=json&wsfunction=core_grades_get_grades&courseid=${courseid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
}; */

/* #gradereport_user_get_grade_items */
/* v1.0 */

export const getGradeReportbyCourse = async (args) => {
  try {
    const { token, id, course_id, baseURL } = args;
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=gradereport_user_get_grade_items&courseid=${course_id}&userid=${id}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

/* core_course_get_contents */

export const getCoreCourseContent = async (args) => {
  try {
    const { token, course_id, baseUrl } = args;
    const url = `${baseUrl}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_course_get_contents&courseid=${course_id}`;
    const { data } = await axios.get(url);
    const clonedData = JSON.parse(JSON.stringify(data));
    console.log({
      getCoreCourseContent: data,
      getCoreCourseContentClonedData: clonedData,
      servicio: url,
    });
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

/* core_completion_get_activities_completion_status&userid=3&courseid=18 */
/* v1.0 */

export const getCourseProgress = async (args) => {
  try {
    const { token, course_id, id, baseURL } = args;
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_completion_get_activities_completion_status&userid=${id}&courseid=${course_id}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

/* core_completion_update_activity_completion_status_manually&cmid=74&completed=1 */

export const setActivityProgressCompletedManually = async (args) => {
  try {
    const { token, cmid, completed, baseURL } = args;
    const { data } = await axios.get(
      `${baseURL}/webservice/rest/server.php?wstoken=${token}&moodlewsrestformat=json&wsfunction=core_completion_update_activity_completion_status_manually&cmid=${cmid}&completed=${completed}`
    );
    /*     console.log(data) */
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

/* core_completion_update_activity_completion_status_manually&cmid=74&completed=1 */
/* v1.0 */

export const setActivityProgressCompletedOverride = async (args) => {
  try {
    const { token, cmid, completed, userid } = args;
    const { data } = await axios.get(
      `${moodleURL}${token}&moodlewsrestformat=json&wsfunction=core_completion_override_activity_completion_status&userid=${userid}&cmid=${cmid}&newstate=${completed}`
    );
    /*     console.log(data) */
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

/* wsfunction=mod_resource_view_resource&resourceid=${resourceid} */
/* v1.0 */

export const getModResourceView = async (token, id) => {
  try {
    const { data } = await axios.get(
      `${moodleURL}${token}&moodlewsrestformat=json&wsfunction=mod_resource_view_resource&resourceid=${id}`
    );
    /*     console.log(data) */
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

/* wsfunction=core_completion_mark_course_self_completed&resourceid=${resourceid} */
/* v1.0 */

export const setCompletionCourseSelf = async (token, id) => {
  try {
    const { data } = await axios.get(
      `${moodleURL}${token}&moodlewsrestformat=json&wsfunction=core_completion_mark_course_self_completed&resourceid=${id}`
    );
    /*     console.log(data) */
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
