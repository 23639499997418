import React from "react";
import { LIMIT_CRON } from "../../utils/state";

export const useTimer = () => {
  const lastLogin = localStorage.getItem("last_login_sence");
  const timeInSeconds = LIMIT_CRON;
  const [time, setTime] = React.useState("00:00:00");
  const [limit, setLimit] = React.useState(0);

  React.useEffect(() => {
    if (lastLogin) {
      setInterval(() => {
        cron(lastLogin);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastLogin]);

  let cron = (time) => {
    let raw = new Date(time);
    let now = new Date();

    let dif = now.getTime() - raw.getTime();

    let limit = Math.floor(dif / 1000);

    let Seconds_from_T1_to_T2 = dif / 1000;
    let Seconds_Between_Dates = Math.floor(Seconds_from_T1_to_T2);
    let diff = timeInSeconds - Seconds_Between_Dates;

    let hh = Math.floor(diff / 3600);
    let mm = Math.floor((diff - hh * 3600) / 60);
    let ss = Math.floor(diff - hh * 3600 - mm * 60);

    let hours = hh < 10 ? `0${hh}` : hh;
    let minutes = mm < 10 ? `0${mm}` : mm;
    let seconds = ss < 10 ? `0${ss}` : ss;

    let timer = `${hours}:${minutes}:${seconds}`;
    setLimit(limit);
    setTime(timer);
  };

  return { time, limit };
};
