import React from "react";
import { HeaderCourses } from "../../components/HeaderCourses";
import { ResumeCourses } from "../../components/ResumeCourses";
/* import { CoursesContentSection } from "../../components/CoursesContentSection"; */
import { Footer } from "../../components/Footer";
import { CourseCard } from "../../components/CourseCard";
import { CustomSpinner } from "../../components/Loader/custom.spinner";
import { FormSence } from "../../services/sence/form";
import { useNavigate } from "react-router-dom";
import { CustomDialog } from "../../components/Dialog";
import img1 from "./assets/1a.jpg";
import img2 from "./assets/2a.jpg";
import img3 from "./assets/3a.jpg";
import img4 from "./assets/4a.jpg";
import img5 from "./assets/5a.jpg";
import img6 from "./assets/6a.jpg";
import { useMoodleV2 } from "../../contexts/moodle";
import { useConfig } from "../../contexts/config";
import { STRINGS } from "../../utils/strings";
import { useAuth } from "../../contexts/AuthContext";
import {
  loginMoodle /* , resetPasword  */,
} from "../../services/moodle/userauth";
import { Snackbar } from "../../components/Snackbar";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  DEFAULT_SNACKBAR,
  SUCCESS_FORCEPASSWORD_CHANGE,
} from "../../utils/snackbar";
import { THEME_CONTEXT } from "../../utils/theme";

const ListCourses = () => {
  const images = [img1, img2, img3, img4, img5, img6];
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const isSence = localStorage.getItem("sence_session");
  const currentCourse = localStorage.getItem("current_course");
  const [modalForcePassword, showModalForcePassword] = React.useState(false);
  const moodlev2 = useMoodleV2();

  const [msg, setMsg] = React.useState({
    open: false,
    title: "",
    msg: "",
    confirm: "Ir a Home",
    cancel: "Ir al curso",
  });

  React.useEffect(() => {
    !moodlev2.courses && moodlev2.getCourses();
    !moodlev2.categories && moodlev2.getCategories();
    if (localStorage.getItem(STRINGS.FORCEPASSWORD_ERROR_CODE)) {
      showModalForcePassword(true);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (moodlev2.courses && moodlev2.courses.length > 0) {
      const size = moodlev2.courses.length;
      if (size === 1) {
        setLoading(false);
        setMsg({
          ...msg,
          open: true,
          msg: "Selecciona la navegacion",
          title: "Bienvenido a Alumnos",
        });
        /* window.location.replace(`/detailcourse/${moodle.courses[0].id}`); */
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [moodlev2.courses]);

  React.useEffect(() => {
    if (isSence) navigate(`/detailcourse/v2/${currentCourse}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onCancel() {
    setMsg({ ...msg, open: false });
    navigate(`/detailcourse/v2/${moodlev2.courses[0].id}`);
  }

  function onConfirm() {
    setMsg({ ...msg, open: false });
  }

  const PageView = () => {
    return (
      <>
        <FormSence />
        <HeaderCourses moodle={moodlev2} />
        <ResumeCourses>
          {moodlev2.courses &&
            moodlev2.courses.length > 0 &&
            moodlev2.courses.map((item, idx) => {
              return (
                <CourseCard
                  img={images[Math.floor(Math.random() * images.length)]}
                  key={idx}
                  item={item}
                  moodle={moodlev2}
                />
              );
            })}
        </ResumeCourses>
        <Footer />
        <CustomDialog
          open={msg.open}
          onConfirm={onConfirm}
          onCancel={onCancel}
          title={msg.title}
          message={msg.msg}
          confirm={msg.confirm}
          cancel={msg.cancel}
        />
        <ForcePasswordModal open={modalForcePassword} />
      </>
    );
  };

  return <>{loading ? <CustomSpinner /> : <PageView />}</>;
};

const ForcePasswordModal = ({ open }) => {
  const auth = useAuth();
  const { config } = useConfig();
  const moodle = useMoodleV2();
  const navigate = useNavigate();

  React.useEffect(() => {
    console.log(config.baseURL);
    console.log(auth.user);
    //eslint-disable-next-line
  }, []);

  const [snackbar, setSnackbar] = React.useState({
    severity: "",
    visible: false,
    message: "",
  });
  const [errorAuth, setErrorAuth] = React.useState({
    error: false,
    message: "",
  });
  const [changeCredentials, setChangeCredentials] = React.useState({
    lastPassword: "",
    newPassWord: "",
    confirmPassword: "",
  });

  const handleSubmit = async () => {
    let username = localStorage.getItem("username");
    // let token = localStorage.getItem("token");
    try {
      let credentials = {
        username: username,
        password: changeCredentials.lastPassword,
      };
      await loginMoodle({ credentials, baseUrl: config.baseURL });
      // let reseted = await resetPasword({
      //   args: {
      //     username,
      //     password: changeCredentials.newPassWord,
      //     forcepasswordchange: 1,
      //   },
      //   baseUrl: config.baseURL,
      // });

      localStorage.removeItem(STRINGS.FORCEPASSWORD_ERROR_CODE);

      setSnackbar(SUCCESS_FORCEPASSWORD_CHANGE);

      setTimeout(() => {
        setSnackbar(DEFAULT_SNACKBAR);
        auth.logout(moodle);
        navigate("/");
      }, 5000);

      // console.log({ reseted });
    } catch (err) {
      setErrorAuth({
        error: true,
        message: "Error al validar la contraseña anterior",
      });
      console.log(err);
    }
  };

  return (
    <>
      <div>
        <Dialog
          fullWidth
          open={open}
          /* onClose={handleClose} */ PaperProps={{
            style: {
              backgroundColor: THEME_CONTEXT.NAV_LIGHT,
              paddingBottom: ".7rem",
            },
          }}
        >
          <DialogTitle
            sx={{
              backgroundColor: THEME_CONTEXT.DARK_MODE_BTN,
              color: "#fff",
              fontSize: 16,
              fontFamily:
                "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif",
            }}
          >
            Crea una nueva contraseña
          </DialogTitle>
          <DialogContent className="mt-3">
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Contraseña actual"
              type="email"
              fullWidth
              variant="standard"
              value={changeCredentials.lastPassword}
              onChange={(e) =>
                setChangeCredentials({
                  ...changeCredentials,
                  lastPassword: e.target.value,
                })
              }
              inputProps={{ style: { fontSize: 12, color: "#fff" } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 12, color: "#fff" } }}
            />
            {errorAuth.error && (
              <p style={{ fontSize: 12, color: "red", fontWeight: 400 }}>
                {errorAuth.message}
              </p>
            )}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nueva contraseña"
              type="email"
              fullWidth
              variant="standard"
              value={changeCredentials.newPassWord}
              onChange={(e) =>
                setChangeCredentials({
                  ...changeCredentials,
                  newPassWord: e.target.value,
                })
              }
              inputProps={{ style: { fontSize: 12, color: "#fff" } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 12, color: "#fff" } }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Confirmar contraseña"
              type="email"
              fullWidth
              variant="standard"
              value={changeCredentials.confirmPassword}
              onChange={(e) =>
                setChangeCredentials({
                  ...changeCredentials,
                  confirmPassword: e.target.value,
                })
              }
              inputProps={{ style: { fontSize: 12, color: "#fff" } }} // font size of input text
              InputLabelProps={{ style: { fontSize: 12, color: "#fff" } }}
            />
          </DialogContent>
          <DialogActions>
            {
              <Button
                disabled={Object.values(changeCredentials).some(
                  (v) => v === ""
                )}
                /* onClick={handleClose} */ onClick={() => handleSubmit()}
                variant="contained"
                sx={{
                  backgroundColor: THEME_CONTEXT.DAY_MODE_BTN,
                  fontSize: 12,
                  marginRight: 2,
                  color: "#fff",
                }}
              >
                Confirmar
              </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar
        severity={snackbar.severity}
        visible={snackbar.visible}
        message={snackbar.message}
      />
    </>
  );
};

export default ListCourses;
